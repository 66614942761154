<template>
  <IonPage>
    <IonContent :forceOverscroll="false">
      <div class="compute-step-slider" ref="itself">
        <Swiper
          class="compute-step-slider__swiper"
          direction="horizontal"
          :loop="false"
          :simulate-touch="false"
          auto-height
          @swiper="handleSwiperInit"
          @slideChange="handleSlideChange"
        >
          <SwiperSlide
            class="compute-step-slider__slide"
            v-for="(element, index) in elements"
            :key="`segment-${index}`"
          >
            <transition name="fade">
              <div class="compute-step-slider__wrapper">
                <BaseButton class="compute-step-slider__close" color="light" @click="dismiss">
                  <IonIcon class="compute-step-slider__close-icon" :icon="Icons.Cross" />
                  <span class="compute-step-slider__close-label">{{ $t('wizard.close') }}</span>
                </BaseButton>

                <BaseStepper class="compute-step-slider__stepper" :total="elements.length" :current="index + 1" />

                <p class="compute-step-slider__header">
                  <span class="compute-step-slider__title">
                    <span class="compute-step-slider__tag">
                      {{ index + 1 }}
                    </span>
                    {{ element.title }}
                  </span>
                  <span v-if="element.subtitle" class="compute-step-slider__subtitle">
                    {{ element.subtitle }}
                  </span>
                </p>

                <component
                  :is="element.component"
                  @requestNextStep="index === elements.length - 1 ? dismiss() : swiperInstance.slideNext()"
                />

                <NavigationFooter
                  :is-first="index === 0"
                  :is-last="index === elements.length - 1"
                  @requestNextStep="index === elements.length - 1 ? dismiss() : swiperInstance.slideNext()"
                  @requestPreviousStep="swiperInstance.slidePrev()"
                />
              </div>
            </transition>
          </SwiperSlide>
        </Swiper>
      </div>
    </IonContent>
  </IonPage>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { IonPage, IonContent, IonIcon } from '@ionic/vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import useModal from '@/composables/useModal';
import { useSizeStepStore } from '@/store/sizeStep';
import { Icons } from '@/enums/Icons';
import ComputeWizardSize from '@/components/ComputeWizard/ComputeWizardSize.vue';
import ComputeWizardSurface from '@/components/ComputeWizard/ComputeWizardSurface.vue';
import ComputeWizardFriction from '@/components/ComputeWizard/ComputeWizardFriction.vue';
import ComputeWizardQuality from '@/components/ComputeWizard/ComputeWizardQuality.vue';
import ComputeWizardClamping from '@/components/ComputeWizard/ComputeWizardClamping.vue';
import BaseStepper from '@/components/BaseStepper.vue';
import BaseButton from '@/components/BaseButton.vue';
import NavigationFooter from '@/components/NavigationFooter.vue';

const props = defineProps<{
  requestedStep?: number;
}>();

const { t } = useI18n();
const { dismissCurrentModal } = useModal();
const sizeStepStore = useSizeStepStore();

const itself = ref<any>();
const swiperInstance = ref<any>();

const surfaceStepTitle = computed(() =>
  sizeStepStore.selectedObject
    ? t('home.steps.surface.label_with_size_value', { size_value: sizeStepStore.selectedObject.key })
    : t('home.steps.surface.label')
);

const elements = computed(() => [
  { title: t('home.steps.size.label'), component: ComputeWizardSize },
  {
    title: surfaceStepTitle.value,
    subtitle: t('home.steps.surface_subtitle.label'),
    component: ComputeWizardSurface,
  },
  { title: t('home.steps.friction.label'), component: ComputeWizardFriction },
  { title: t('home.steps.quality.label'), component: ComputeWizardQuality },
  { title: t('home.steps.clamping.label'), component: ComputeWizardClamping },
]);

const handleSwiperInit = (swiper: any) => {
  swiperInstance.value = swiper;
};

const handleSlideChange = () => {
  itself.value.scrollTo(0, 0);
};

const updateSwiper = (immediate = false) => {
  let timer = 1000;
  if (immediate) {
    timer = 1;
  }
  const timeout = setTimeout(() => {
    swiperInstance.value.updateAutoHeight();
    clearTimeout(timeout);
  }, timer);
};

const dismiss = async () => {
  await dismissCurrentModal();
};

onMounted(() => {
  updateSwiper(false);

  if (props.requestedStep && props.requestedStep >= 0 && props.requestedStep < elements.value.length) {
    swiperInstance.value.slideTo(props.requestedStep);
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/placeholders/bold' as *;
@use '@/assets/scss/lib/mixins/sr-only' as *;

.compute-step-slider {
  padding-top: env(safe-area-inset-top);
  background-color: white;
  height: 100%;

  &__close {
    align-self: flex-end;
    height: px-to-rem(55px);
    width: px-to-rem(55px);
    flex-shrink: 0;

    &::part(native) {
      width: 3em;
    }
  }

  &__close-label {
    @include sr-only();
  }

  &__close-icon {
    color: black;
    font-size: px-to-rem(16px);
  }

  &__stepper {
    margin: 0;
  }

  &__swiper {
    width: 100%;
  }

  &__slide {
    color: var(--ion-color-dark);
    height: calc(100vh - env(safe-area-inset-top));
    overflow: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    max-width: 30em;
    margin: 0 auto;
  }

  &__header {
    @extend %page-content-spacing;
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);
    width: 1.5em;
    height: 1.5em;
    font-size: 0.67em;
    border-radius: 50%;
    margin-right: 0.5em;
  }

  &__title {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.125em;
    text-transform: uppercase;
  }

  &__subtitle {
    display: block;
    text-align: center;
    @extend %bold;
    font-size: 0.875em;
  }
}
</style>
