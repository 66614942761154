export enum RouteName {
  ROOT = 'root',
  // ----------------------------------------------------------------------------------------------------------------
  // CUSTOM PAGES
  // ----------------------------------------------------------------------------------------------------------------
  HOME = 'home',
  NEWS = 'news',
  SETTINGS = 'settings',
  COMPUTE = 'compute',
  // ----------------------------------------------------------------------------------------------------------------
  // STEPS
  // ----------------------------------------------------------------------------------------------------------------
  SIZE_AND_STEP = 'size_and_pitch',
  BEARING_SURFACE = 'bearing_surface',
  FRICTION = 'friction',
  QUALITY_CLASS = 'quality_class',
  CLAMPING_TOLL = 'clamping_tool',
}

export enum RoutePath {
  ROOT = '/',
  // ----------------------------------------------------------------------------------------------------------------
  // CUSTOM PAGES
  // ----------------------------------------------------------------------------------------------------------------
  HOME = '/home',
  NEWS = '/news',
  SETTINGS = '/settings',
  COMPUTE_STEP_SIZE = '/compute/size-and-pitch',
  COMPUTE_STEP_SURFACE = '/compute/bearing-surface',
  COMPUTE_STEP_FRICTION = '/compute/friction',
  COMPUTE_STEP_QUALITY = '/compute/quality-class',
  COMPUTE_STEP_CLAMPING = '/compute/clamping-tool',
}
