export enum QualityMaterial {
  CarbonSteel = 'carbon_steel_according_to_iso_898_1',
  StainlessSteel = 'stainless_steel_according_to_iso_3506_1',
  SaeGrade = 'steel_according_to_sae_grade',
}

export enum GradeVariant {
  Value_4_6 = 'Value_4_6',
  Value_4_8 = 'Value_4_8',
  Value_5_6 = 'Value_5_6',
  Value_5_8 = 'Value_5_8',
  Value_6_8 = 'Value_6_8',
  Value_8_8 = 'Value_8_8',
  Value_9_8 = 'Value_9_8',
  Value_10_9 = 'Value_10_9',
  Value_12_9 = 'Value_12_9',
  Austenitic_50 = 'Austenitic_50',
  Austenitic_70 = 'Austenitic_70',
  Austenitic_80 = 'Austenitic_80',
  Austenitic_100 = 'Austenitic_100',
  Duplex_70 = 'Duplex_70',
  Duplex_80 = 'Duplex_80',
  Duplex_100 = 'Duplex_100',
  Martensitic_50 = 'Martensitic_50',
  Martensitic_70 = 'Martensitic_70',
  Martensitic_80 = 'Martensitic_80',
  Martensitic_110 = 'Martensitic_110',
  Ferritic_45 = 'Ferritic_45',
  Ferritic_60 = 'Ferritic_60',
  Grade_2 = 'Grade_2',
  Grade_5 = 'Grade_5',
  Grade_7 = 'Grade_7',
  Grade_8 = 'Grade_8',
}
