<template>
  <IonPage class="news-page">
    <IonContent class="news-page__content" color="primary" :forceOverscroll="false">
      <div class="news-page__content-container">
        <header class="news-page__header">
          <BaseButton class="news-page__close-button" color="primary" @click="goBack">
            <BaseIcon class="news-page__close-button-icon" :icon="Icons.Cross"></BaseIcon>
          </BaseButton>
        </header>

        <IonText class="news-page__title-container news-page__title-container--main-title">
          <h1 class="news-page__title">{{ $t('news.page.title') }}</h1>
        </IonText>

        <NewsSection
          class="news-page__section"
          v-for="(section, index) in data"
          :key="`section-${index}`"
          :section="section"
        />
      </div>
    </IonContent>
  </IonPage>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue';
import { IonContent, IonPage, IonText, useIonRouter } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Icons } from '@/enums/Icons';
import { Lang } from '@/enums/Lang';
import { RouteName } from '@/enums/Router';
import { Platform } from '@/enums/Platform';
import useNewsSettings from '@/composables/useNewsSettings';
import useLangSettings from '@/composables/useLangSettings';
import BaseButton from '@/components/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import NewsSection from '@/components/News/NewsSection.vue';

const langSettings = useLangSettings();
const router = useIonRouter();

const { t } = useI18n();

const data = computed(() => {
  let news = [
    {
      title: t('news.page.software_title'),
      items: [
        {
          imageUrl: require('@/assets/images/news-page/cobra.jpg'),
          title: t('news.articles.cobra_software.title'),
          content: t('news.articles.cobra_software.description'),
          seeMoreLink: t('news.articles.cobra_software.link'),
        },
      ],
      actions: [
        {
          title: t('news.actions.cobra_software.label'),
          link: t('news.actions.cobra_software.link'),
          icon: Icons.Envelope,
        },
      ],
    },
    {
      title: t('news.page.training_title'),
      items: [
        {
          imageUrl: require('@/assets/images/news-page/academy.jpg'),
          title: t('news.articles.cetim_academy.title'),
          content: t('news.articles.cetim_academy.description'),
          seeMoreLink: t('news.articles.cetim_academy.link'),
        },
      ],
      actions: [
        {
          title: t('news.actions.cetim_academy.label'),
          link: t('news.actions.cetim_academy.link'),
          icon: Icons.Envelope,
        },
      ],
    },
    {
      title: t('news.page.issues_title'),
      items: [
        {
          imageUrl: require('@/assets/images/news-page/integrity.jpg'),
          title: t('news.articles.cetim_integrity.title'),
          content: t('news.articles.cetim_integrity.description'),
          seeMoreLink: t('news.articles.cetim_integrity.link'),
        },
      ],
      actions: [
        {
          title: t('news.actions.cetim_integrity.label'),
          link: t('news.actions.cetim_integrity.link'),
          icon: Icons.Envelope,
        },
      ],
    },
    {
      title: t('news.page.question_title'),
      actions: [
        {
          title: t('news.actions.website.label'),
          link: t('news.actions.website.link'),
          icon: Icons.Info,
        },
        {
          title: t('news.actions.mail.label'),
          link: t('news.actions.mail.link'),
          icon: Icons.Envelope,
        },
        {
          title: t('news.actions.call.label'),
          link: t('news.actions.call.link'),
          icon: Icons.Phone,
        },
        {
          title: t('news.actions.info.label'),
          link: t('news.actions.info.link'),
          icon: Icons.Info,
        },
      ],
    },
  ];

  if (langSettings.currentLocale() === Lang.Fr.Iso) {
    news.splice(0, 0, assembliesDaysSection);
    news.splice(news.length - 1, 0, futureSection);
  }

  return news;
});

const futureSection = {
  title: t('news.page.future_title'),
  items: [
    {
      imageUrl: require('@/assets/images/news-page/quatrium.jpg'),
      imageStyle: {
        'object-fit': 'contain',
      },
      title: t('news.articles.quatrium.title'),
      content: t('news.articles.quatrium.description'),
      seeMoreLink: t('news.articles.quatrium.link'),
    },
  ],
  actions: [
    {
      title: t('news.actions.quatrium.label'),
      link: t('news.actions.quatrium.link'),
      icon: Icons.Envelope,
    },
  ],
};

const assembliesDaysSection = {
  title: t('news.page.assembly_days_title'),
  items: [
    {
      imageUrl: require('@/assets/images/news-page/assembly.png'),
      title: t('news.articles.assemblies_days.title'),
      content: t('news.articles.assemblies_days.description'),
      seeMoreLink: t('news.articles.assemblies_days.link'),
    },
  ],
  actions: [],
};

const goBack = () => {
  if (Capacitor.getPlatform() === Platform.WEB) {
    router.push({ name: RouteName.HOME });
  } else {
    router.back();
  }
};

onMounted(() => {
  useNewsSettings().checkLastVisit();

  if (Capacitor.getPlatform() === Platform.IOS) {
    StatusBar.setStyle({ style: Style.Dark });
  }
});

onUnmounted(() => {
  if (Capacitor.getPlatform() === Platform.IOS) {
    StatusBar.setStyle({ style: Style.Light });
  }
});
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/functions/px-to-rem' as *;
@use '@/assets/scss/lib/mixins/sr-only' as *;

.news-page {
  @extend %page;

  &__content-container {
    @extend %page-content-container;
    margin-bottom: 5em;
  }

  &__content {
    font-size: px-to-rem(16px);
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  &__close-button {
    margin: 0;
    height: px-to-rem(55px);
    width: px-to-rem(55px);
  }

  &__close-button-icon {
    font-size: px-to-em(16px);
  }

  &__title-container {
    &--main-title {
      @include sr-only();
    }
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    font-size: px-to-rem(20px);
    margin: 0;
  }
}
</style>
