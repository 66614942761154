<template>
  <ComputPage
    class="compute-clamping-page"
    :prev-url="RoutePath.COMPUTE_STEP_QUALITY"
    :next-url="RoutePath.HOME"
    :title="$t('home.steps.clamping.label')"
    :current-step="5"
    @on-next-step="runMainCalculation"
    update-final-result-on-close
  >
    <ComputeWizardClamping />
  </ComputPage>
</template>

<script lang="ts" setup>
import ComputPage from '@/components/ComputePage.vue';
import ComputeWizardClamping from '@/components/ComputeWizard/ComputeWizardClamping.vue';
import { RoutePath } from '@/enums/Router';
import { useComputeWizardStore } from '@/store/computeWizard';

const computeWizardStore = useComputeWizardStore();

const runMainCalculation = () => {
  computeWizardStore.updateFinalResult();
};
</script>

<style lang="scss" scoped>
.compute-clamping-page {
  //
}
</style>
