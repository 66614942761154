import { defineStore } from 'pinia';
import { FinalResult } from '@/composables/useCalculationResult';
import { useClampingStepStore } from '@/store/clampingStep';
import { useSizeStepStore } from '@/store/sizeStep';
import { useSurfaceStepStore } from '@/store/surfaceStep';
import { useFrictionStepStore } from '@/store/frictionStep';
import { useQualityStepStore } from '@/store/qualityStep';
import { getFinalResult } from '@/composables/useCalculationResult';

export interface ComputeWizardStore {
  computedValues: FinalResult | undefined;
}

export const useComputeWizardStore = defineStore('computeWizard', {
  state: (): ComputeWizardStore => {
    return {
      computedValues: undefined,
    };
  },
  getters: {
    isValid(state): boolean {
      return state.computedValues !== undefined;
    },
  },
  actions: {
    resetValues(): void {
      this.computedValues = undefined;

      useClampingStepStore().resetValues();
      useSizeStepStore().resetValues();
      useSurfaceStepStore().resetValues();
      useFrictionStepStore().resetValues();
      useQualityStepStore().resetValues();
    },
    updateFinalResult(): void {
      this.computedValues = getFinalResult();
    },
  },
});
