import { defineStore } from 'pinia';
import { FrictionType } from '@/enums/Friction';

import { FrictionTypeValue, frictionTypeValues } from '@/data/FrictionValue';
import useFindEnumKeyFromValue from '@/composables/useFindEnumKeyFromValue';
import { useI18n } from 'vue-i18n';

export interface FrictionStepStore {
  type: FrictionType | undefined;
  specificCoefficientMin: number | undefined;
  specificCoefficientMax: number | undefined;
}

export const useFrictionStepStore = defineStore('frictionStep', {
  state: (): FrictionStepStore => {
    return {
      type: FrictionType.Medium,
      specificCoefficientMin: undefined,
      specificCoefficientMax: undefined,
    };
  },
  getters: {
    specificCoefficientStep(): number {
      return 0.001;
    },
    specificCoefficientMinMinimumValue(): number {
      return 0;
    },
    specificCoefficientMinMaximumValue(): number {
      return 1;
    },
    specificCoefficientMaxMinimumValue(): number {
      return this.specificCoefficientMin ?? 0;
    },
    specificCoefficientMaxMaximumValue(): number {
      return 1;
    },
    frictionTypeValue(state): FrictionTypeValue | undefined {
      return frictionTypeValues.find((item) => {
        return item.type === state.type;
      });
    },
    coefficientMin(state): number {
      const specificCoefficientMin = state.specificCoefficientMin ? state.specificCoefficientMin : -1;
      return this.frictionTypeValue ? this.frictionTypeValue.coefficientMin : specificCoefficientMin;
    },
    coefficientMax(state): number {
      const specificCoefficientMax = state.specificCoefficientMax ? state.specificCoefficientMax : -1;
      return this.frictionTypeValue ? this.frictionTypeValue.coefficientMax : specificCoefficientMax;
    },
    stepButtonValue(): string {
      const { t } = useI18n();

      if (this.type) {
        return t(`friction.type_values.${useFindEnumKeyFromValue(FrictionType, this.type)}`);
      }

      return t('friction.specific_values', { min: this.specificCoefficientMin, max: this.specificCoefficientMax });
    },
  },
  actions: {
    resetValues(): void {
      this.type = FrictionType.Medium;
      this.specificCoefficientMin = undefined;
      this.specificCoefficientMax = undefined;
    },
    setType(newType: FrictionType | undefined): void {
      if (newType) {
        this.type = newType;
        this.specificCoefficientMin = undefined;
        this.specificCoefficientMax = undefined;
      }
    },
    setSpecificCoefficientMin(newSpecificCoefficientMin: any | undefined): void {
      const typeOfNewValue = typeof newSpecificCoefficientMin;
      if ((typeOfNewValue === 'number' || typeOfNewValue === 'string') && newSpecificCoefficientMin !== '') {
        this.specificCoefficientMin = parseFloat(newSpecificCoefficientMin);
      } else {
        this.specificCoefficientMin = undefined;
      }

      this.checkFrictionType();
    },
    setSpecificCoefficientMax(newSpecificCoefficientMax: any | undefined): void {
      const typeOfNewValue = typeof newSpecificCoefficientMax;
      if ((typeOfNewValue === 'number' || typeOfNewValue === 'string') && newSpecificCoefficientMax !== '') {
        this.specificCoefficientMax = parseFloat(newSpecificCoefficientMax);
      } else {
        this.specificCoefficientMax = undefined;
      }

      this.checkFrictionType();
    },
    checkFrictionType(): void {
      if (this.specificCoefficientMin && this.specificCoefficientMax) {
        this.type = undefined;
      }

      if (!this.type && !this.specificCoefficientMin && !this.specificCoefficientMax) {
        this.type = FrictionType.Medium;
      }
    },
  },
});
