import { useLocalStorage } from '@vueuse/core';
import { LocalStorageKey } from '@/enums/LocalStorage';
import { Unit } from '@/enums/Unit';

export default () => {
  const unit = useLocalStorage(LocalStorageKey.SettingUnit, Unit.NewtonMeter);

  const clampingLevel = useLocalStorage(LocalStorageKey.SettingClampingLevel, 90);

  return {
    unit,
    clampingLevel,
  };
};
