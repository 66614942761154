<template>
  <IonGrid class="step-button-grid">
    <StepButton
      class="step-button-grid__button"
      v-for="(item, index) in steps"
      :key="`step-${index}`"
      :label="item.label"
      :icon="item.icon"
      :order="index + 1"
      :is-valid="item.isValid"
      :value="item.value"
      @click="$emit('requestDisplayStep', index)"
    ></StepButton>
  </IonGrid>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { IonGrid } from '@ionic/vue';
import { Icons } from '@/enums/Icons';
import { useSizeStepStore } from '@/store/sizeStep';
import { useSurfaceStepStore } from '@/store/surfaceStep';
import { useFrictionStepStore } from '@/store/frictionStep';
import { useQualityStepStore } from '@/store/qualityStep';
import { useClampingStepStore } from '@/store/clampingStep';
import { useComputeWizardStore } from '@/store/computeWizard';
import StepButton from '@/components/StepButton.vue';
import { StepButtonProps } from '@/types/StepButtonProps';
import { RoutePath } from '@/enums/Router';

defineEmits(['requestDisplayStep']);

const sizeStepStore = useSizeStepStore();
const surfaceStepStore = useSurfaceStepStore();
const frictionStepStore = useFrictionStepStore();
const qualityStepStore = useQualityStepStore();
const clampingStepStore = useClampingStepStore();
const computeWizardStore = useComputeWizardStore();

const { t } = useI18n();

// ---------------------------------------------------------------------------------------------------------------------
// Size Step values
// ---------------------------------------------------------------------------------------------------------------------
const sizeStepButton = computed((): StepButtonProps => {
  return {
    label: t('home.steps.size.label'),
    icon: Icons.Size,
    isValid: computeWizardStore.isValid,
    value: sizeStepStore.stepButtonValue,
    routerLink: RoutePath.COMPUTE_STEP_SIZE,
  };
});

// ---------------------------------------------------------------------------------------------------------------------
// Surface Step values
// ---------------------------------------------------------------------------------------------------------------------
const surfaceStepButton = computed((): StepButtonProps => {
  return {
    label: t('home.steps.surface.label'),
    icon: Icons.Surface,
    isValid: computeWizardStore.isValid,
    value: surfaceStepStore.stepButtonValue,
    routerLink: RoutePath.COMPUTE_STEP_SURFACE,
  };
});

// ---------------------------------------------------------------------------------------------------------------------
// Friction Step values
// ---------------------------------------------------------------------------------------------------------------------
const frictionStepButton = computed((): StepButtonProps => {
  return {
    label: t('home.steps.friction.label'),
    icon: Icons.Friction,
    isValid: computeWizardStore.isValid,
    value: frictionStepStore.stepButtonValue,
    routerLink: RoutePath.COMPUTE_STEP_FRICTION,
  };
});

// ---------------------------------------------------------------------------------------------------------------------
// Quality Step values
// ---------------------------------------------------------------------------------------------------------------------
const qualityStepButton = computed((): StepButtonProps => {
  return {
    label: t('home.steps.quality.label'),
    icon: Icons.Quality,
    isValid: computeWizardStore.isValid,
    value: qualityStepStore.stepButtonValue,
    routerLink: RoutePath.COMPUTE_STEP_QUALITY,
  };
});

// ---------------------------------------------------------------------------------------------------------------------
// Clamping Step values
// ---------------------------------------------------------------------------------------------------------------------
const clampingStepButton = computed((): StepButtonProps => {
  return {
    label: t('home.steps.clamping.label'),
    icon: Icons.Tool,
    isValid: computeWizardStore.isValid,
    value: clampingStepStore.stepButtonValue,
    routerLink: RoutePath.COMPUTE_STEP_CLAMPING,
  };
});

// ---------------------------------------------------------------------------------------------------------------------
// Final values
// ---------------------------------------------------------------------------------------------------------------------

const steps = computed((): Array<StepButtonProps> => {
  return [
    sizeStepButton.value,
    surfaceStepButton.value,
    frictionStepButton.value,
    qualityStepButton.value,
    clampingStepButton.value,
  ];
});
</script>

<style lang="scss" scoped>
.step-button-grid {
  display: grid;
  grid-template-columns: calc(50% - var(--grid-gap) / 2) calc(50% - var(--grid-gap) / 2);
  grid-auto-flow: row dense;
  width: 100%;
  gap: var(--grid-gap);
}
</style>
