import { SurfaceGenericType } from '@/enums/Surface';

export interface SurfaceGenericTypeValue {
  type: SurfaceGenericType;
  innerDiameter: number;
  externalDiameter: number;
}

export const surfaceGenericTypeValues: Array<SurfaceGenericTypeValue> = [
  {
    type: SurfaceGenericType.Hexagonal,
    innerDiameter: 0.309165,
    externalDiameter: 1.404767,
  },
  {
    type: SurfaceGenericType.Cylindrical,
    innerDiameter: 0.344446,
    externalDiameter: 1.456969,
  },
  {
    type: SurfaceGenericType.WithBasePlate,
    innerDiameter: -0.135836,
    externalDiameter: 1.902125,
  },
];
