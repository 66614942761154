import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import { RouteName, RoutePath } from '@/enums/Router';
import NewsPage from '@/views/NewsPage.vue';
import SettingsPage from '@/views/SettingsPage.vue';
import ComputeSizePage from '@/views/ComputeSizePage.vue';
import ComputeSurfacePage from '@/views/ComputeSurfacePage.vue';
import ComputeFrictionPage from '@/views/ComputeFrictionPage.vue';
import ComputeQualityPage from '@/views/ComputeQualityPage.vue';
import ComputeClampingPage from '@/views/ComputeClampingPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutePath.ROOT,
    name: RouteName.ROOT,
    redirect: RoutePath.HOME,
  },
  {
    path: RoutePath.HOME,
    name: RouteName.HOME,
    component: HomePage,
  },
  {
    path: RoutePath.NEWS,
    name: RouteName.NEWS,
    component: NewsPage,
  },
  {
    path: RoutePath.SETTINGS,
    name: RouteName.SETTINGS,
    component: SettingsPage,
  },
  {
    path: RoutePath.COMPUTE_STEP_SIZE,
    name: RouteName.SIZE_AND_STEP,
    component: ComputeSizePage,
  },
  {
    path: RoutePath.COMPUTE_STEP_SURFACE,
    name: RouteName.BEARING_SURFACE,
    component: ComputeSurfacePage,
  },
  {
    path: RoutePath.COMPUTE_STEP_FRICTION,
    name: RouteName.FRICTION,
    component: ComputeFrictionPage,
  },
  {
    path: RoutePath.COMPUTE_STEP_QUALITY,
    name: RouteName.QUALITY_CLASS,
    component: ComputeQualityPage,
  },
  {
    path: RoutePath.COMPUTE_STEP_CLAMPING,
    name: RouteName.CLAMPING_TOLL,
    component: ComputeClampingPage,
  },
  { path: '/:pathMatch(.*)*', redirect: RoutePath.HOME },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
