<template>
  <IonPage class="home-page">
    <IonContent class="home-page__container" :forceOverscroll="false">
      <div class="home-page__content-container">
        <header class="home-page__header">
          <BaseButton
            class="home-page__header-button"
            fill="clear"
            :router-link="RoutePath.NEWS"
            @click="newsSettings.checkLastVisit"
          >
            <BaseIcon :icon="Icons.Light" />
            <span class="home-page__news-badge" v-if="newsSettings.hasNews()"></span>
          </BaseButton>
          <img :src="logo" alt="" class="home-page__header-logo" width="48" height="55" />
          <BaseButton class="home-page__header-button" fill="clear" :router-link="RoutePath.SETTINGS">
            <BaseIcon :icon="Icons.Gear" />
          </BaseButton>
        </header>
        <main class="home-page__content">
          <CalculationResult v-if="computeWizardStore.isValid" />
          <IonText color="light" v-else>
            <p class="home-page__intro">{{ $t('home.intro') }}</p>
          </IonText>

          <div class="home-page__button-wrapper">
            <BaseButton
              class="home-page__button"
              rounded
              expand="block"
              color="light"
              @click="resetFinalCalculus"
              :icon="Icons.NextArrow"
            >
              {{ buttonLabel }}
            </BaseButton>
          </div>

          <StepButtonGrid class="home-page__step-grid" @requestDisplayStep="displayCalculusStep" />
        </main>
      </div>
    </IonContent>
  </IonPage>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { IonPage, IonContent, IonText } from '@ionic/vue';
import { RoutePath } from '@/enums/Router';
import logo from '@/assets/images/logo.svg';
import BaseButton from '@/components/BaseButton.vue';
import ComputeStepSlider from '@/components/ComputeStepSlider.vue';
import { useI18n } from 'vue-i18n';
import { Icons } from '@/enums/Icons';
import BaseIcon from '@/components/BaseIcon.vue';
import { useComputeWizardStore } from '@/store/computeWizard';
import StepButtonGrid from '@/components/StepButtonGrid.vue';
import CalculationResult from '@/components/CalculationResult.vue';
import useNewsSettings from '@/composables/useNewsSettings';
import useModal from '@/composables/useModal';

const newsSettings = useNewsSettings();
const computeWizardStore = useComputeWizardStore();
const { displayAsModal } = useModal();

const { t } = useI18n();

const buttonLabel = computed(() => {
  return computeWizardStore.isValid ? t('home.new') : t('home.start');
});

const resetFinalCalculus = () => {
  computeWizardStore.resetValues();
  displayCalculusStep();
};

const displayCalculusStep = async (stepIndex = 0) => {
  displayAsModal(ComputeStepSlider, { requestedStep: stepIndex }).then(() => {
    computeWizardStore.updateFinalResult();
  });
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.home-page {
  @extend %page;

  --background: transparent;
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: rgb(107, 116, 132);
  background-image: linear-gradient(120deg, rgba(107, 116, 132, 1) 0%, rgba(187, 199, 218, 1) 100%);

  &__news-badge {
    display: block;
    background-color: var(--ion-color-primary);
    border-radius: 50%;
    height: px-to-rem(15px);
    width: px-to-rem(15px);
    position: absolute;
    top: px-to-rem(10px);
    right: px-to-rem(10px);
  }

  &__content-container {
    @extend %page-content-container;
  }

  &__container {
    --background: transparent;
    --padding-top: 1.125em;
    --padding-bottom: 1.125em;
    --padding-start: 1.125em;
    --padding-end: 1.125em;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-button {
    height: px-to-rem(55px);
    width: px-to-rem(55px);
  }

  &__intro {
    text-align: center;
    margin-top: px-to-rem(40px);
    margin-bottom: px-to-rem(10px);
    font-size: px-to-rem(20px);
  }

  &__button {
    font-size: px-to-rem(18px);
  }

  &__step-grid {
    margin-top: 2.5em;
  }
}
</style>
