<template>
  <div class="compute-wizard-input-accordion">
    <IonAccordionGroup
      class="compute-wizard-input-accordion__accordion-group"
      @ionChange="$emit('change', $event.detail.value !== undefined)"
      :value="isAccordionOpen ? 'ion-accordion-0' : undefined"
    >
      <IonAccordion class="compute-wizard-input-accordion__accordion">
        <IonItem lines="full" class="compute-wizard-input-accordion__accordion-header-item" slot="header">
          <IonLabel class="compute-wizard-input-accordion__accordion-header-label">
            {{ title }}
          </IonLabel>
        </IonItem>

        <slot />
      </IonAccordion>
    </IonAccordionGroup>
  </div>
</template>

<script setup lang="ts">
import { IonAccordionGroup, IonAccordion, IonItem, IonLabel } from '@ionic/vue';
import { defineProps, ref, watch } from 'vue';

const isAccordionOpen = ref<boolean>(false);

const props = withDefaults(
  defineProps<{
    title: string;
    shouldBeOpen?: boolean;
  }>(),
  {
    title: undefined,
    shouldBeOpen: false,
  }
);

watch(props, (newValue) => {
  isAccordionOpen.value = newValue.shouldBeOpen;
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/lib/placeholders/bold' as *;
@use '@/assets/scss/lib/functions/px-to-em' as *;

.compute-wizard-input-accordion {
  width: 100%;

  &__accordion-header-label {
    @extend %bold;
  }

  &__accordion-header-item {
    --padding-top: #{px-to-em(8px)};
    --padding-bottom: #{px-to-em(8px)};

    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
  }
}
</style>
