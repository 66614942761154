import { ClampingTool } from '@/enums/Clamping';

export interface ClampingToolValue {
  tool: ClampingTool;
  value: number;
}

export const clampingToolValues: Array<ClampingToolValue> = [
  {
    tool: ClampingTool.TriggerTorqueWrench15,
    value: 15,
  },
  {
    tool: ClampingTool.ElectronicTorqueWrench10,
    value: 10,
  },
  {
    tool: ClampingTool.PneumaticScrewdriver20,
    value: 20,
  },
  {
    tool: ClampingTool.ServoElectricScrewdriver10,
    value: 10,
  },
  {
    tool: ClampingTool.ServoControlledAutomaticElectricScrewdriver5,
    value: 5,
  },
];
