import { Capacitor } from '@capacitor/core';
import { useI18n } from 'vue-i18n';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Media, MediaAlbum, MediaSaveOptions } from '@capacitor-community/media';
import { Screenshot } from 'capacitor-screenshot';
import { Platform } from '@/enums/Platform';

export default () => {
  const albumName = 'Torque Tightening';
  const { t } = useI18n();

  const saveScreenshot = async (): Promise<string> => {
    const b64Image = await Screenshot.take();

    const dateObj = new Date();
    const fileName = `torque-tightening_screenshot-${dateObj.getUTCFullYear()}${
      dateObj.getUTCMonth() + 1
    }${dateObj.getUTCDate()}-${dateObj.getHours()}${dateObj.getMinutes()}${dateObj.getSeconds()}.jpeg`;

    const ensureAlbumExists = async (retryAllowed = true): Promise<MediaAlbum> => {
      const { albums } = await Media.getAlbums();
      let ttAlbum = undefined;
      if (Capacitor.getPlatform() === Platform.ANDROID) {
        const albumsPath = (await Media.getAlbumsPath()).path;
        ttAlbum = albums.find((a) => a.name === albumName && a.identifier.startsWith(albumsPath));
        console.log(ttAlbum);
      } else {
        ttAlbum = albums.find((a) => a.name === albumName);
      }

      if (!ttAlbum) {
        await Media.createAlbum({ name: albumName });
        if (retryAllowed) {
          ttAlbum = await ensureAlbumExists(false);
        } else {
          throw new Error(t('error'));
        }
      }

      return ttAlbum;
    };

    const screenshotTemp = await Filesystem.writeFile({
      data: b64Image.base64,
      path: fileName,
      directory: Directory.Data,
      recursive: true,
    });

    const ttAlbum = await ensureAlbumExists();

    const opts: MediaSaveOptions = { path: screenshotTemp.uri, albumIdentifier: ttAlbum.identifier, fileName };
    await Media.savePhoto(opts);

    return screenshotTemp.uri;
  };

  return { saveScreenshot };
};
