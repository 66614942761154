import useAlertOnEvent from '@/composables/useAlertOnEvent';
import { ConditionType } from '@/enums/Condition';
import { useSurfaceStepStore } from '@/store/surfaceStep';
import { useI18n } from 'vue-i18n';
import { InvalidFormatType } from '@/enums/InvalidFormat';

export default () => {
  const alertManager = useAlertOnEvent();
  const surfaceStepStore = useSurfaceStepStore();
  const { t } = useI18n();

  const validateSpecificValues = async (): Promise<boolean> => {
    if (surfaceStepStore.genericType !== undefined) {
      return true;
    }
    if (
      surfaceStepStore.specificInnerBearingDiameter === undefined ||
      surfaceStepStore.specificInnerBearingDiameter < surfaceStepStore.specificInnerBearingDiameterMinValue ||
      surfaceStepStore.specificInnerBearingDiameter > surfaceStepStore.specificInnerBearingDiameterMaxValue
    ) {
      await alertManager.fireConditionAlert({
        field_name: t('surface.specific_settings.inner_bearing_diameter_input_label_simplified'),
        current_value: surfaceStepStore.specificInnerBearingDiameter || '',
        conditionType: ConditionType.Between,
        min: surfaceStepStore.specificInnerBearingDiameterMinValue,
        max: surfaceStepStore.specificInnerBearingDiameterMaxValue,
      });
      return false;
    }

    if (
      surfaceStepStore.specificExternalBearingDiameter === undefined ||
      surfaceStepStore.specificExternalBearingDiameter < surfaceStepStore.specificExternalBearingDiameterMinValue ||
      surfaceStepStore.specificExternalBearingDiameter > surfaceStepStore.specificExternalBearingDiameterMaxValue
    ) {
      await alertManager.fireConditionAlert({
        field_name: t('surface.specific_settings.external_bearing_diameter_input_label_simplified'),
        current_value: surfaceStepStore.specificExternalBearingDiameter || '',
        conditionType: ConditionType.Between,
        min: surfaceStepStore.specificExternalBearingDiameterMinValue,
        max: surfaceStepStore.specificExternalBearingDiameterMaxValue,
      });
      return false;
    }

    return true;
  };

  const validateDecimalValues = async (): Promise<boolean> => {
    if (surfaceStepStore.genericType !== undefined) {
      return true;
    }
    if (
      surfaceStepStore.specificInnerBearingDiameter !== undefined &&
      surfaceStepStore.specificInnerBearingDiameter?.toString() !== '' &&
      surfaceStepStore.specificExternalBearingDiameter !== undefined &&
      surfaceStepStore.specificExternalBearingDiameter?.toString() !== ''
    ) {
      const innerBearingDecimals = surfaceStepStore.specificInnerBearingDiameter.toString().split('.')[1];
      const externalBearingDecimals = surfaceStepStore.specificExternalBearingDiameter.toString().split('.')[1];
      let acceptedDecimalCount = 2;

      if (surfaceStepStore.specificInnerBearingDiameter > 100) {
        acceptedDecimalCount = 1;
      }

      if (innerBearingDecimals && innerBearingDecimals.length > acceptedDecimalCount) {
        await alertManager.fireInvalidFormat({
          field_name: t('surface.specific_settings.inner_bearing_diameter_input_label_simplified'),
          current_value: surfaceStepStore.specificInnerBearingDiameter,
          invalidFormatType: InvalidFormatType.MaxDecimal,
          maxDecimal: acceptedDecimalCount,
        });
        return false;
      }

      acceptedDecimalCount = 2;

      if (surfaceStepStore.specificExternalBearingDiameter > 100) {
        acceptedDecimalCount = 1;
      }

      if (externalBearingDecimals && externalBearingDecimals.length > acceptedDecimalCount) {
        await alertManager.fireInvalidFormat({
          field_name: t('surface.specific_settings.external_bearing_diameter_input_label_simplified'),
          current_value: surfaceStepStore.specificExternalBearingDiameter,
          invalidFormatType: InvalidFormatType.MaxDecimal,
          maxDecimal: acceptedDecimalCount,
        });
        return false;
      }
    }

    return true;
  };

  const validateTheStep = async (): Promise<boolean> => {
    return (await validateSpecificValues()) && (await validateDecimalValues());
  };

  return {
    validateTheStep,
    validateSpecificValues,
    validateDecimalValues,
  };
};
