// https://www.andiamo.co.uk/resources/iso-language-codes/
export const Lang = Object.freeze({
  Fr: {
    Iso: 'fr',
    Label: 'Français',
    ShortLabel: 'Fr',
  },
  En: {
    Iso: 'en',
    Label: 'English',
    ShortLabel: 'En',
  },
});
