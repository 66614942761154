<template>
  <ComputeWizardStepContainer class="compute-wizard-size">
    <SegmentSelect
      @on-change="onSizeSelectChange"
      class="compute-wizard-size__size"
      v-model="sizeStepStore.unit"
      :options="units"
    ></SegmentSelect>
    <SegmentSelect
      @on-change="onSelectChange"
      class="compute-wizard-size__pitch-type"
      v-model="sizeStepStore.type"
      :options="weights"
    ></SegmentSelect>
    <DropdownSelect
      class="compute-wizard-size__pitch"
      :label="$t('size.type_input_label')"
      v-model="sizeStepStore.size"
      :options="sizeOptions"
    />

    <img class="compute-wizard-size__image" :src="Size" />
  </ComputeWizardStepContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ComputeWizardStepContainer from '@/components/ComputeWizard/ComputeWizardStepContainer.vue';
import DropdownSelect from '@/components/DropdownSelect.vue';
import SegmentSelect from '@/components/SegmentSelect.vue';
import useTranslateEnums from '@/composables/useTranslateEnums';
import { SizeUnit, SizeTypeImperial, SizeTypeMetric } from '@/enums/Size';
import { useSizeStepStore } from '@/store/sizeStep';
import {
  defaultSizePitchValue,
  imperialValues,
  metricValues,
  SizeMetricValue,
  SizeImperialValue,
} from '@/data/SizeValues';
import { TranslateElement } from '@/types/TranslateElement';
import Size from '@/assets/images/step-schemas/size-and-pitch.png';

const { translate } = useTranslateEnums();
const sizeStepStore = useSizeStepStore();

const metricUnits = computed((): Array<TranslateElement> => {
  return translate(SizeTypeMetric, 'size.weight');
});

const imperialUnits = computed((): Array<TranslateElement> => {
  return translate(SizeTypeImperial, 'size.weight');
});

// Units

const units = computed((): Array<TranslateElement> => {
  return translate(SizeUnit, 'size.units');
});

// Weights
const weights = computed(() => {
  return sizeStepStore.unit === SizeUnit.Metric ? metricUnits.value : imperialUnits.value;
});

// Sizes
const sizeOptions = computed(() => {
  let values: Array<SizeMetricValue | SizeImperialValue> =
    sizeStepStore.unit === SizeUnit.Metric ? metricValues : imperialValues;

  values = values
    .filter((item: any) => {
      return item.pitch[sizeStepStore.type] !== undefined;
    })
    .sort((a: any, b: any) => {
      return a.value - b.value;
    });

  return values.map((item: any) => {
    item.label = item.key;
    return item;
  });
});

const onSelectChange = () => {
  if (
    sizeStepStore.unit === SizeUnit.Metric &&
    (sizeStepStore.type === SizeTypeMetric.Big || sizeStepStore.type === SizeTypeMetric.Slim)
  ) {
    sizeStepStore.setSize(defaultSizePitchValue);
    return;
  }

  if (sizeOptions.value && sizeOptions.value[0]) {
    sizeStepStore.setSize(sizeOptions.value[0].value);
  }
};

const onSizeSelectChange = () => {
  if (sizeStepStore.unit === SizeUnit.Metric) {
    sizeStepStore.setType(SizeTypeMetric.Big);
  } else {
    sizeStepStore.setType(SizeTypeImperial.UNC);
  }

  onSelectChange();
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.compute-wizard-size {
  &__size {
    max-width: px-to-rem(300px);
  }

  &__pitch-type {
    max-width: px-to-rem(480px);
  }

  &__pitch-type,
  &__size,
  &__pitch {
    width: 100%;
  }

  &__image {
    margin: px-to-em(20px) auto;
    width: 70%;
    object-fit: contain;
  }
}
</style>
