import CalculationConstant from '@/data/CalculationConstant';
import { useSizeStepStore } from '@/store/sizeStep';
import { useQualityStepStore } from '@/store/qualityStep';
import { useSurfaceStepStore } from '@/store/surfaceStep';
import { useFrictionStepStore } from '@/store/frictionStep';
import { useClampingStepStore } from '@/store/clampingStep';
import { Unit } from '@/enums/Unit';
import useCalculationSettings from '@/composables/useCalculationSettings';
import Logger from '@/utils/Logger';

export interface ComputedRangValue {
  torque: number | undefined;
  tension: number | undefined;
  constraint: number | undefined;
}

export interface FinalResult {
  finalValue: number | undefined;
  washerHardness: number | undefined;
  min: ComputedRangValue;
  moy: ComputedRangValue;
  max: ComputedRangValue;
  unit: string;
}

export const getThreadFlankDiameter = (): number => {
  const sizeStepStore = useSizeStepStore();
  return sizeStepStore.sizeValue - CalculationConstant.CalculD2 * sizeStepStore.pitchValue;
};

export const getThreadBottomDiameter = (): number => {
  const sizeStepStore = useSizeStepStore();
  return sizeStepStore.sizeValue - CalculationConstant.CalculD3 * sizeStepStore.pitchValue;
};

export const getFrictionRadius = (): number => {
  const surfaceStepStore = useSurfaceStepStore();

  const d_w = surfaceStepStore.surfaceExternalDiameter;
  if (surfaceStepStore.specificExternalBearingDiameter) {
    Logger.log('Diamètre exterieur spécifique saisi :');
    Logger.log('do = d_w = ', surfaceStepStore.specificExternalBearingDiameter);
  } else {
    Logger.log('Pas de diamètre exterieur spécifique saisi :');
    Logger.log("Diam ext. d'appui = ", surfaceStepStore.genericType);
    Logger.log('cst_BS = ', surfaceStepStore.innerBearingDiameter);
    Logger.log('Coef_BS = ', surfaceStepStore.externalBearingDiameter);

    Logger.log('do = d_w = coef_BS*Size_value + cst_BS');
    Logger.log(
      '    = ',
      surfaceStepStore.externalBearingDiameter,
      ' * ',
      useSizeStepStore().sizeValue,
      ' + ',
      surfaceStepStore.innerBearingDiameter
    );
    Logger.log('    = ', d_w);
  }

  const d_i = surfaceStepStore.surfaceInnerDiameter;
  if (surfaceStepStore.specificInnerBearingDiameter) {
    Logger.log('Diamètre intérieur spécifique saisi :');
    Logger.log('dh = d_i = ', surfaceStepStore.specificInnerBearingDiameter);
  } else {
    Logger.log('Pas de diamètre intérieur spécifique saisi :');
    Logger.log('dh = d_i = 1.085894 * Size_value + 0.153965');
    Logger.log(
      '    = ',
      CalculationConstant.CalculDi1,
      ' * ',
      useSizeStepStore().sizeValue,
      ' + ',
      CalculationConstant.CalculDi2
    );
    Logger.log('    = ', d_i);
  }

  Logger.log('rm = (d_i^3 - d_w^3)/(3 * (d_i^2 - d_w^2)');
  Logger.log('    = (', d_i, '^3 - ', d_w, '^3)/(3 * (', d_i, '^2 - ', d_w, '^2)');
  const result = (Math.pow(d_i, 3) - Math.pow(d_w, 3)) / (3 * (Math.pow(d_i, 2) - Math.pow(d_w, 2)));
  Logger.log('    = ', result);
  return result;
};

export const getSpecificFrictionCoefficient = (torque: number, preload: number): number => {
  return getSpecificRoundedValue(
    ((torque * 1000) / preload - CalculationConstant.Coefficient1 * useSizeStepStore().pitchValue) /
      (CalculationConstant.Coefficient2 * getThreadFlankDiameter() + getFrictionRadius())
  );
};

export const getSpecificRoundedValue = (value: number): number => {
  // By default : value > 10000
  let result = Math.round(value / 100) * 100;

  if (value < 1) {
    result = Math.round(value * 1000) / 1000;
  } else if (value >= 1 && value < 10) {
    result = Math.round(value * 100) / 100;
  } else if (value >= 10 && value < 100) {
    result = Math.round(value * 10) / 10;
  } else if (value >= 100 && value < 500) {
    result = Math.round(value);
  } else if (value >= 500 && value < 10000) {
    result = Math.round(value / 10) * 10;
  }

  return result;
};

export const getWasherHardness = (): number => {
  const storeQualityValue = useQualityStepStore().qualityValue;

  // By default : toreQualityValue > 1100
  let result = 380;

  if (storeQualityValue <= 480) {
    result = 100;
  } else if (storeQualityValue > 480 && storeQualityValue <= 660) {
    result = 200;
  } else if (storeQualityValue > 660 && storeQualityValue <= 940) {
    result = 300;
  }

  return result;
};

export const getFinalResult = (
  settingUnit: Unit | string | undefined = undefined,
  settingClampingLevel: number | undefined = undefined
): FinalResult => {
  Logger.log('---------------------------------------------');
  Logger.log('Tightening_level_value = ', settingClampingLevel);
  const calculationSettings = useCalculationSettings();
  settingUnit = settingUnit || calculationSettings.unit.value;
  settingClampingLevel = settingClampingLevel || calculationSettings.clampingLevel.value;
  Logger.log('calculationSettings clampingLevel = ', calculationSettings.clampingLevel.value);
  Logger.log('calculationSettings unit = ', calculationSettings.unit.value);

  const sizeStepStore = useSizeStepStore();
  const frictionStepStore = useFrictionStepStore();

  const storeSizeValue = sizeStepStore.sizeValue;
  Logger.log('Size_value = ', storeSizeValue);
  const storePitchValue = sizeStepStore.pitchValue;
  Logger.log('Pitch_value = ', storePitchValue);
  const storeQualityValue = useQualityStepStore().qualityValue;
  Logger.log('Grade_Re_value = ', storeQualityValue);
  const storeMinFrictionValue = frictionStepStore.coefficientMin;
  Logger.log('MU_min = ', storeMinFrictionValue);
  const storeMaxFrictionValue = frictionStepStore.coefficientMax;
  Logger.log('MU_max = ', storeMaxFrictionValue);
  const storeClampingToolValue = useClampingStepStore().clampingValue;
  Logger.log('Tools_disp_value', storeClampingToolValue);

  Logger.log('---------------------------------------------');

  let torqueMaxValue;
  let torqueValue;
  let torqueMinValue;

  let tensionMaxValue;
  let tensionValue;
  let tensionMinValue;

  let constraintMaxValue;
  let constraintValue;
  let constraintMinValue;

  Logger.log('d2 = Size_value - (0.6495 * Pitch_value)');
  Logger.log('    = ', storeSizeValue, ' - (', CalculationConstant.CalculD2, ' * ', storePitchValue, ')');
  const d2 = getThreadFlankDiameter();
  Logger.log('    = ', d2);

  Logger.log('d3 = Size_value - (1.2268 * Pitch_value)');
  Logger.log('   = ', storeSizeValue, ' - (', CalculationConstant.CalculD3, ' * ', storePitchValue, ')');
  const d3 = getThreadBottomDiameter();
  Logger.log('d3 = ', d3);

  const rm = getFrictionRadius();

  Logger.log('sequ = PI * ((d2+d3)/2)^2 / 4');
  Logger.log('    = PI * ((', d2, ' + ', d3, ')/2)^2 / 4');
  const sequ = (Math.PI * Math.pow((d2 + d3) / 2, 2)) / 4;
  Logger.log('    = ', sequ);

  Logger.log('dequ = Size_value - (0.9382 * Pitch_value)');
  Logger.log('    = ', storeSizeValue, ' - (0.9382 * ', storePitchValue, ')');
  const dequ = storeSizeValue - CalculationConstant.CalculDequ * storePitchValue;
  Logger.log('    = ', dequ);

  // Must be calculated -> (Math.PI * Math.pow((d2 + d3) / 2, 2)) / 4;

  Logger.log('coef1 = ', CalculationConstant.Coefficient1);
  Logger.log('coef2 = ', CalculationConstant.Coefficient2);

  Logger.log('equationA = (coef1 * Pitch_value) + ((coef_friction_TH_min * coef2 * d2) + (coef_friction_HE_min * rm))');
  Logger.log(
    '    = (',
    CalculationConstant.Coefficient1,
    ' * ',
    storePitchValue,
    ') + ((',
    storeMinFrictionValue,
    ' * ',
    CalculationConstant.Coefficient2,
    ' * ',
    d2,
    ') + (',
    storeMinFrictionValue,
    ' * ',
    rm,
    '))'
  );
  const equationA =
    CalculationConstant.Coefficient1 * storePitchValue +
    (storeMinFrictionValue * CalculationConstant.Coefficient2 * d2 + storeMinFrictionValue * rm);
  Logger.log('    = ', equationA);

  Logger.log('equationB = (coef1 * Pitch_value) + ((coef_friction_TH_max * coef2 * d2) + (coef_friction_HE_max * rm))');
  Logger.log(
    '    = (',
    CalculationConstant.Coefficient1,
    ' * ',
    storePitchValue,
    ') + ((',
    storeMaxFrictionValue,
    ' * ',
    CalculationConstant.Coefficient2,
    ' * ',
    d2,
    ') + (',
    storeMaxFrictionValue,
    ' * ',
    rm,
    '))'
  );
  const equationB =
    CalculationConstant.Coefficient1 * storePitchValue +
    (storeMaxFrictionValue * CalculationConstant.Coefficient2 * d2 + storeMaxFrictionValue * rm);
  Logger.log('    = ', equationB);

  Logger.log('equationC = (equationA + equationB) / 2');
  Logger.log('    = (', equationA, ' + ', equationB, ') / 2');
  const equationC = (equationA + equationB) / 2;
  Logger.log('    = ', equationC);

  Logger.log('Tightening_level_value = (EditText_tigh_level.Text/100) * Grade_Re_value');
  Logger.log('    = (', settingClampingLevel, ' / 100) * ', storeQualityValue);
  const tighteningLevelValue = (settingClampingLevel / 100) * storeQualityValue;
  Logger.log('    = ', tighteningLevelValue);

  // Couple max
  Logger.log(
    'Torque_max_value = (Tightening_level_value/1000) / Sqrt((1/(Equation_A*Sequ)^2) + (3*(16*(1-((MU_min*rm)/Equation_A)) / (PI * dequ^3))^2))'
  );
  Logger.log(
    '    = (',
    tighteningLevelValue,
    '/1000) / Sqrt((1/(',
    equationA,
    ' * ',
    sequ,
    '))^2 + (3*(16*(1-((',
    storeMinFrictionValue,
    ' * ',
    rm,
    ')/',
    equationA,
    ')) / (PI * ',
    dequ,
    '^3) ))^2))'
  );
  torqueMaxValue =
    tighteningLevelValue /
    1000 /
    Math.sqrt(
      Math.pow(1 / (equationA * sequ), 2) +
        3 * Math.pow((16 * (1 - (storeMinFrictionValue * rm) / equationA)) / (Math.PI * Math.pow(dequ, 3)), 2)
    );
  Logger.log('    = ', torqueMaxValue);

  // Couple moyen
  Logger.log('Torque_value = Torque_max_value /(1 + Tools_disp_value)');
  Logger.log('    = ', torqueMaxValue, '/ (1 + ', storeClampingToolValue, ')');
  torqueValue = torqueMaxValue / (1 + storeClampingToolValue);
  Logger.log('*   = ', torqueValue, ' *');

  // Couple min
  Logger.log('Torque_min_value = Torque_value * (1 - Tools_disp_value)');
  Logger.log('    = ', torqueValue, ' *(1 - ', storeClampingToolValue, ')');
  torqueMinValue = torqueValue * (1 - storeClampingToolValue);
  Logger.log('    = ', torqueMinValue);

  // calcul de la tension
  Logger.log('Preload_max_value = (1000 * Torque_max_value)/Equation_A');
  Logger.log('    = (1000 * ', torqueMaxValue, ')/', equationA);
  tensionMaxValue = (1000 * torqueMaxValue) / equationA;
  Logger.log('    = ', tensionMaxValue);

  Logger.log('Preload_value = (1000 * Torque_value)/Equation_C');
  Logger.log('    = (1000 * ', torqueValue, ')/', equationC);
  tensionValue = (1000 * torqueValue) / equationC;
  Logger.log('    = ', tensionValue);

  Logger.log('Preload_min_value = (1000 * Torque_min_value)/Equation_B');
  Logger.log('    = (1000 * ', torqueMinValue, ')/', equationB);
  tensionMinValue = (1000 * torqueMinValue) / equationB;
  Logger.log('    = ', tensionMinValue);

  Logger.log('coef_friction_TH_min = MU_min');
  Logger.log('    = ', storeMinFrictionValue);

  Logger.log('coef_friction_TH_min = MU_max');
  Logger.log('    = ', storeMaxFrictionValue);

  Logger.log('coef_friction_TH_moy = (MIN + MAX)/2 = MU_moy');
  Logger.log('    = (', storeMinFrictionValue, ' + ', storeMaxFrictionValue, ') / 2');
  const mu_moy = (storeMaxFrictionValue + storeMinFrictionValue) / 2;
  Logger.log('    = ', mu_moy);

  Logger.log(
    'Torsion_stress_max = (16*Preload_max_value*((coef1 * Pitch_value) + (MU_min * coef2 * d2)))/(PI * dequ^3)'
  );
  Logger.log(
    '   = (16*',
    tensionMaxValue,
    '*((',
    CalculationConstant.Coefficient1,
    ' * ',
    storePitchValue,
    ') + (',
    storeMinFrictionValue,
    ' * ',
    CalculationConstant.Coefficient2,
    ' * ',
    d2,
    ' )))/(PI * ',
    dequ,
    '^3)'
  );
  const torsionStressMax =
    (16 *
      tensionMaxValue *
      (CalculationConstant.Coefficient1 * storePitchValue +
        storeMinFrictionValue * CalculationConstant.Coefficient2 * d2)) /
    (Math.PI * Math.pow(dequ, 3));
  Logger.log('    = ', torsionStressMax);

  Logger.log('Torsion_stress_moy = (16*Preload_value*((coef1 * Pitch_value) + (MU_moy * coef2 * d2)))/(PI * dequ^3)');
  Logger.log(
    '   = (16 * ',
    tensionValue,
    '*((',
    CalculationConstant.Coefficient1,
    ' * ',
    storePitchValue,
    ') + (',
    mu_moy,
    ' * ',
    CalculationConstant.Coefficient2,
    ' * ',
    d2,
    ')))/(PI * ',
    dequ,
    '^3))'
  );
  const torsionStressMoy =
    (16 *
      tensionValue *
      (CalculationConstant.Coefficient1 * storePitchValue + mu_moy * CalculationConstant.Coefficient2 * d2)) /
    (Math.PI * Math.pow(dequ, 3));
  Logger.log('    = ', torsionStressMoy);

  Logger.log(
    'Torsion_stress_min = (16*Preload_min_value*((coef1 * Pitch_value) + (MU_max * coef2 * d2)))/(PI * Power(dequ,3) )'
  );
  Logger.log(
    '    = (16 * ',
    tensionMinValue,
    '*((',
    CalculationConstant.Coefficient1,
    ' * ',
    storePitchValue,
    ') + (',
    storeMaxFrictionValue,
    ' * ',
    CalculationConstant.Coefficient2,
    ' * ',
    d2,
    ')))/(PI * ',
    dequ,
    '^3))'
  );
  const torsionStressMin =
    (16 *
      tensionMinValue *
      (CalculationConstant.Coefficient1 * storePitchValue +
        storeMaxFrictionValue * CalculationConstant.Coefficient2 * d2)) /
    (Math.PI * Math.pow(dequ, 3));
  Logger.log('   = ', torsionStressMin);

  Logger.log('Traction_stress_max =  Preload_max_value / Sequ');
  Logger.log('    = ', tensionMaxValue, ' / ', sequ);
  const tractionStressMax = tensionMaxValue / sequ;
  Logger.log('    = ', tractionStressMax);

  Logger.log('Traction_stress_moy = Preload_value / Sequ');
  Logger.log('    = ', tensionValue, ' / ', sequ);
  const tractionStressMoy = tensionValue / sequ;
  Logger.log('    = ', tractionStressMoy);

  Logger.log('Traction_stress_max = Preload_max_value / Sequ');
  Logger.log('    = ', tensionMinValue, ' / ', sequ);
  const tractionStressMin = tensionMinValue / sequ;
  Logger.log('    = ', tractionStressMin);

  Logger.log('Equ_stress_max = Sqrt(Traction_stress_max^2 + (3*Torsion_stress_max^2))');
  Logger.log('    = Sqrt(', tractionStressMax, '^2 + (3*', torsionStressMax, '^2))');
  constraintMaxValue = Math.sqrt(Math.pow(tractionStressMax, 2) + 3 * Math.pow(torsionStressMax, 2));
  Logger.log('    = ', constraintMaxValue);

  Logger.log('Equ_stress_moy = Sqrt(Traction_stress_moy^2 + (3*Torsion_stress_moy^2))');
  Logger.log('    = Sqrt(', tractionStressMoy, '^2 + (3*', torsionStressMoy, '^2))');
  constraintValue = Math.sqrt(Math.pow(tractionStressMoy, 2) + 3 * Math.pow(torsionStressMoy, 2));
  Logger.log('    = ', constraintValue);

  Logger.log('Equ_stress_min = Sqrt(Traction_stress_min^2 + (3*Torsion_stress_min^2))');
  Logger.log('    = Sqrt(', tractionStressMin, '^2 + (3*', torsionStressMin, '^2))');
  constraintMinValue = Math.sqrt(Math.pow(tractionStressMin, 2) + 3 * Math.pow(torsionStressMin, 2));
  Logger.log('    = ', constraintMinValue);

  // Convertir en ft.lbf
  if (settingUnit === Unit.FootPoundForce) {
    Logger.log('Conversion ft.lbf');
    torqueMaxValue = torqueMaxValue * CalculationConstant.CoefficientFtlbf;
    Logger.log('torqueMaxValue = ', torqueMaxValue);
    torqueValue = torqueMaxValue / (1 + storeClampingToolValue);
    Logger.log('torqueValue = ', torqueValue);
    torqueMinValue = torqueValue * (1 - storeClampingToolValue);
    Logger.log('torqueMinValue = ', torqueMinValue);
  }

  //Calcul du couple
  Logger.log("Calcul de l'arrondi");
  torqueMaxValue = getSpecificRoundedValue(torqueMaxValue);
  Logger.log('torqueMaxValue = ', torqueMaxValue);
  torqueValue = getSpecificRoundedValue(torqueValue);
  Logger.log('torqueValue = ', torqueValue);
  torqueMinValue = getSpecificRoundedValue(torqueMinValue);
  Logger.log('torqueMinValue = ', torqueMinValue);

  tensionMaxValue = getSpecificRoundedValue(tensionMaxValue);
  tensionValue = getSpecificRoundedValue(tensionValue);
  tensionMinValue = getSpecificRoundedValue(tensionMinValue);

  constraintMaxValue = Math.round(constraintMaxValue);
  constraintValue = Math.round(constraintValue);
  constraintMinValue = Math.round(constraintMinValue);

  return {
    finalValue: torqueValue > 0 ? torqueValue : -1,
    washerHardness: getWasherHardness(),
    min: {
      torque: torqueMinValue,
      tension: tensionMinValue,
      constraint: constraintMinValue,
    },
    moy: {
      torque: torqueValue,
      tension: tensionValue,
      constraint: constraintValue,
    },
    max: {
      torque: torqueMaxValue,
      tension: tensionMaxValue,
      constraint: constraintMaxValue,
    },
    unit: calculationSettings.unit.value,
  };
};
