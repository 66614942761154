<template>
  <div class="calculation-result" v-if="computeWizardStore.isValid">
    <IonCard class="calculation-result__card calculation-result__card--main">
      <BaseButton
        v-if="isMobile"
        class="calculation-result__save-screen-shot-button"
        fill="clear"
        @click="onScreenShotButtonClick"
      >
        <BaseIcon :icon="Icons.Save" />
      </BaseButton>
      <IonText class="calculation-result__main-result">{{ computeWizardStore.computedValues.finalValue }}</IonText>
      <IonText class="calculation-result__result-unit">{{ computeWizardStore.computedValues.unit }}</IonText>
      <IonText class="calculation-result__washer-hardness">{{ washerHardnessLabel }}</IonText>
    </IonCard>

    <IonCard class="calculation-result__card calculation-result__card--secondary">
      <table class="calculation-result__table">
        <thead class="calculation-result__table-head">
          <tr class="calculation-result__table-line">
            <th class="calculation-result__table-head-cell"></th>
            <th class="calculation-result__table-head-cell">
              {{ $t('result.table.head.torque') }}<br />{{ computeWizardStore.computedValues.unit }}
            </th>
            <th class="calculation-result__table-head-cell">{{ $t('result.table.head.tension') }}<br />N</th>
            <th class="calculation-result__table-head-cell">
              {{ $t('result.table.head.constraint') }}<br />N/mm<sup>2</sup>
            </th>
          </tr>
        </thead>
        <tbody class="calculation-result__table-body">
          <tr class="calculation-result__table-line">
            <th class="calculation-result__table-line-cell calculation-result__table-line-cell--head">
              {{ $t('result.table.body.min') }}
            </th>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.min.torque }}</td>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.min.tension }}</td>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.min.constraint }}</td>
          </tr>
          <tr class="calculation-result__table-line">
            <th class="calculation-result__table-line-cell calculation-result__table-line-cell--head">
              {{ $t('result.table.body.moy') }}
            </th>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.moy.torque }}</td>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.moy.tension }}</td>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.moy.constraint }}</td>
          </tr>
          <tr class="calculation-result__table-line">
            <th class="calculation-result__table-line-cell calculation-result__table-line-cell--head">
              {{ $t('result.table.body.max') }}
            </th>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.max.torque }}</td>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.max.tension }}</td>
            <td class="calculation-result__table-line-cell">{{ computeWizardStore.computedValues.max.constraint }}</td>
          </tr>
        </tbody>
      </table>
    </IonCard>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { IonCard, IonText } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { Dialog } from '@capacitor/dialog';
import { useComputeWizardStore } from '@/store/computeWizard';
import usePhoneGallery from '@/composables/usePhoneGallery';
import useLoader from '@/composables/useLoader';
import { Platform } from '@/enums/Platform';
import { Icons } from '@/enums/Icons';
import BaseButton from '@/components/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';

const { t } = useI18n();
const { saveScreenshot } = usePhoneGallery();
const { showLoader, hideLoader } = useLoader();

const computeWizardStore = useComputeWizardStore();

const isMobile = ref(Capacitor.getPlatform() === Platform.IOS || Capacitor.getPlatform() === Platform.ANDROID);

const washerHardnessLabel = computed(() => {
  return t('result.washer_hardness', { value: computeWizardStore?.computedValues?.washerHardness });
});

const onScreenShotButtonClick = async () => {
  await showLoader(t('screenshot.loading'));

  try {
    const pictureUri = await saveScreenshot();

    await hideLoader();

    const { value } = await Dialog.confirm({
      title: t('screenshot.success_title'),
      message: t('screenshot.success_message'),
      okButtonTitle: t('screenshot.share_action'),
      cancelButtonTitle: t('screenshot.cancel_action'),
    });

    if (value) {
      await Share.share({
        title: t('screenshot.title'),
        text: t('screenshot.text'),
        url: pictureUri,
        dialogTitle: '',
      });
    }
  } catch (e: any) {
    if (e.message !== 'Share canceled') {
      alert(e.message);
    }
  } finally {
    await hideLoader();
  }
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/lib/placeholders/bold' as *;
@use '@/assets/scss/lib/functions/px-to-em' as *;
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.calculation-result {
  &__card {
    --ion-card-background: rgba(255, 255, 255, 0.2);
    --color: var(--ion-color-light);
    display: flex;
    flex-direction: column;
    padding: px-to-em(15px);
    margin: 0 0 px-to-em(10px);
    font-size: px-to-rem(16px);

    &--main {
      position: relative;
      min-height: px-to-rem(55px);
    }
  }

  &__save-screen-shot-button {
    position: absolute;
    top: 0;
    right: 0;
    height: px-to-rem(55px);
    width: px-to-rem(55px);
    margin: 0;
  }

  &__main-result,
  &__result-unit,
  &__washer-hardness {
    text-align: center;
  }

  &__main-result {
    @extend %bold;

    font-size: px-to-em(40px);
  }

  &__table {
    text-align: center;
  }

  &__table-head-cell,
  &__table-line-cell {
    padding: 5px;
  }
}
</style>
