import useAlertOnEvent from '@/composables/useAlertOnEvent';
import { useI18n } from 'vue-i18n';
import { useClampingStepStore } from '@/store/clampingStep';
import { ConditionType } from '@/enums/Condition';
import { InvalidFormatType } from '@/enums/InvalidFormat';

export default () => {
  const alertManager = useAlertOnEvent();
  const clampingStepStore = useClampingStepStore();
  const { t } = useI18n();

  const validateSpecificValues = async (): Promise<boolean> => {
    if (
      clampingStepStore.specificSetting !== undefined &&
      clampingStepStore.specificSetting?.toString() !== '' &&
      (clampingStepStore.specificSetting <= 0 || clampingStepStore.specificSetting > 100)
    ) {
      await alertManager.fireConditionAlert({
        field_name: t('clamping.specific_settings.input_label_simplified'),
        current_value: clampingStepStore.specificSetting,
        conditionType: ConditionType.Between,
        min: 0,
        max: 100,
      });
      return false;
    }

    return true;
  };

  const validateDecimalValues = async (): Promise<boolean> => {
    if (clampingStepStore.specificSetting !== undefined) {
      const specificSettingDecimals = clampingStepStore.specificSetting.toString().split('.')[1];
      const acceptedDecimalCount = 1;

      if (specificSettingDecimals && specificSettingDecimals.length > acceptedDecimalCount) {
        await alertManager.fireInvalidFormat({
          field_name: t('clamping.specific_settings.input_label_simplified'),
          current_value: clampingStepStore.specificSetting,
          invalidFormatType: InvalidFormatType.MaxDecimal,
          maxDecimal: acceptedDecimalCount,
        });
        return false;
      }
    }

    return true;
  };

  const validateTheStep = async (): Promise<boolean> => {
    return (await validateSpecificValues()) && (await validateDecimalValues());
  };

  return {
    validateTheStep,
    validateSpecificValues,
    validateDecimalValues,
  };
};
