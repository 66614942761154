<template>
  <ComputeWizardStepContainer class="compute-wizard-quality">
    <SegmentSelect
      @on-change="onDisplayedMaterialChange"
      v-model="qualityStepStore.selectedMaterial"
      class="compute-wizard-quality__grade"
      :options="grades"
    ></SegmentSelect>

    <DropdownSelect
      class="compute-wizard-quality__grade-variant"
      :label="dropDownLabel"
      v-model="qualityStepStore.selectedGradeVariant"
      :options="variantOptions"
    />

    <img class="compute-wizard-quality__image" :src="stepSchemaImage" />

    <ComputeWizardInputAccordion
      class="compute-wizard-quality__input-accordion"
      :title="$t('quality.specific_settings.accordion_label')"
    >
      <IonItem lines="full" class="compute-wizard-quality__input-accordion-item" slot="content">
        <IonLabel class="compute-wizard-quality__input-accordion-label">{{
          $t('quality.specific_settings.input_label')
        }}</IonLabel>
        <IonInput
          class="compute-wizard-quality__input-accordion-input"
          type="number"
          inputMode="decimal"
          pattern="[0-9]*"
          step="1"
          min="1"
          v-model="qualityStepStore.specificValue"
          placeholder="x"
          @ionChange="onSpecificValueChange"
          enterkeyhint="done"
          @keyup.enter="$emit('requestNextStep')"
        />
      </IonItem>
    </ComputeWizardInputAccordion>
  </ComputeWizardStepContainer>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { IonInput, IonItem, IonLabel } from '@ionic/vue';
import { useQualityStepStore } from '@/store/qualityStep';
import SelectOption from '@/types/SelectOption';
import { QualityMaterial } from '@/enums/Quality';
import { carbonSteelVariant, saeGradeVariant, stainlessSteelVariant } from '@/data/QualityValues';
import useTranslateEnums from '@/composables/useTranslateEnums';
import DropdownSelect from '@/components/DropdownSelect.vue';
import SegmentSelect from '@/components/SegmentSelect.vue';
import ComputeWizardStepContainer from '@/components/ComputeWizard/ComputeWizardStepContainer.vue';
import ComputeWizardInputAccordion from '@/components/ComputeWizard/ComputeWizardInputAccordion.vue';
import useLangSettings from '@/composables/useLangSettings';
import { TranslateElement } from '@/types/TranslateElement';

defineEmits(['requestNextStep']);

const { t } = useI18n();
const { translate } = useTranslateEnums();
const qualityStepStore = useQualityStepStore();

const grades = computed((): Array<TranslateElement> => {
  return translate(QualityMaterial, 'quality.grades');
});

const qualityVariantForGrade = (grade: QualityMaterial) => {
  switch (grade) {
    case QualityMaterial.StainlessSteel:
      return stainlessSteelVariant;
    case QualityMaterial.SaeGrade:
      return saeGradeVariant;
    default:
      return carbonSteelVariant;
  }
};

const stepSchemaImage = computed(() => {
  return require(`@/assets/images/step-schemas/property-class-${useLangSettings().currentLocale()}.png`);
});

const variantOptions: ComputedRef<Array<SelectOption>> = computed(() => {
  const selectedMaterial = qualityStepStore.selectedMaterial;
  if (selectedMaterial === undefined || selectedMaterial.toString() === '') {
    return [];
  }

  return qualityVariantForGrade(selectedMaterial).map((element) => {
    return { label: t(`quality.grade_variants.${selectedMaterial}.${element.variant}`), value: element.variant };
  });
});

const dropDownLabel = computed(() => {
  if (!qualityStepStore.selectedMaterial || !qualityStepStore.selectedGradeVariant) {
    return t('quality.grade_variants.grade_variant_input_label');
  }

  return t(`quality.grade_variants.${qualityStepStore.selectedMaterial}.${qualityStepStore.selectedGradeVariant}`);
});

const onDisplayedMaterialChange = (event: QualityMaterial | undefined) => {
  qualityStepStore.setSelectedMaterial(event);
};

const onSpecificValueChange = (event: CustomEvent) => {
  qualityStepStore.setSpecificValue(event.detail.value);
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.compute-wizard-quality {
  &__input-accordion-item {
    --padding-top: #{px-to-rem(8px)};
    --padding-bottom: #{px-to-rem(8px)};
  }

  &__input-accordion-label {
    max-width: unset;
  }

  &__input-accordion-input {
    text-align: end;
  }

  &__image {
    width: 100%;
    padding-left: px-to-rem(10px);
    padding-right: px-to-rem(10px);
  }
}
</style>
