export enum SizeUnit {
  Metric = 'metric',
  Imperial = 'imperial',
}

export enum SizeTypeMetric {
  Big = 'big',
  Slim = 'slim',
  SuperSlim = 'super_slim',
}

export enum SizeTypeImperial {
  UNC = 'unc',
  UNF = 'unf',
  EightUN = 'eight_un',
}
