import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { loadingController } from '@ionic/vue';

export default () => {
  const { t } = useI18n();
  const loader = ref<HTMLIonLoadingElement | undefined>(undefined);
  return {
    showLoader: async (message: string = t('loading')) => {
      loader.value = await loadingController.create({
        spinner: 'circular',
        message,
      });
      loader.value.present();
    },
    hideLoader: async () => {
      if (loader.value) {
        await loader.value.dismiss();
      }
    },
  };
};
