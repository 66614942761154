<template>
  <div :class="classList">
    <div class="segment-select__action-container" v-if="popUpLabels && popUpLabels.length">
      <div
        class="segment-select__action-item"
        v-for="(option, index) in options"
        :key="`segment-select-action-item-${index}-${option.value}`"
      >
        <IonButton
          v-if="popUpLabels[index]"
          class="segment-select__info-action"
          color="light"
          @click="onInfoClick(popUpLabels[index])"
        >
          <BaseIcon class="segment-select__info-action-icon" :icon="Icons.Info"></BaseIcon>
        </IonButton>
      </div>
    </div>
    <IonSegment class="segment-select__element" @ionChange="emitNewValue" :value="modelValue">
      <IonSegmentButton
        v-for="(option, index) in options"
        :key="`segment-select-${index}-${option.value}`"
        :value="option.value"
        class="segment-select__button"
      >
        {{ option.label }}
      </IonSegmentButton>
    </IonSegment>
  </div>
</template>

<script setup lang="ts">
import { IonButton } from '@ionic/vue';
import { Icons } from '@/enums/Icons';
import { IonSegment, IonSegmentButton } from '@ionic/vue';
import BaseIcon from '@/components/BaseIcon.vue';
import { computed } from 'vue';
import useAlertOnEvent from '@/composables/useAlertOnEvent';

const alertOnEvent = useAlertOnEvent();

const props = defineProps<{
  modelValue?: string | undefined;
  popUpLabels?: Array<string> | undefined;
  options: Array<{
    label: string;
    value: string;
  }>;
}>();

const classList = computed(() => {
  return ['segment-select', { 'segment-select--with-info-action': props.popUpLabels && props.popUpLabels.length }];
});

const emit = defineEmits(['update:modelValue', 'onChange']);

const emitNewValue = (event: any) => {
  emit('update:modelValue', event.detail.value);
  emit('onChange', event.detail.value);
};

const onInfoClick = async (header: string) => {
  await alertOnEvent.fireSimpleAlert(header);
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.segment-select {
  $this: &;

  width: 100%;
  position: relative;

  &--with-info-action {
    #{$this}__button {
      --padding-top: #{px-to-rem(35px)};
    }
  }

  &__element {
    @extend %page-content-spacing;

    width: 100%;
    --background: var(--ion-color-light);
    position: relative;
    border-radius: 0;
    padding-top: 1px;
    padding-bottom: 1px;

    &::before {
      border-radius: 10px;
      content: '';
      position: absolute;
      top: 0;
      left: px-to-rem(19px);
      box-sizing: border-box;
      width: calc(100% - #{px-to-rem(38px)});
      height: 100%;
      display: block;
      border: 1px solid var(--ion-color-primary);
    }
  }

  &__button {
    --color: var(--ion-color-primary);
    --color-checked: var(--ion-color-light);
    --indicator-color: var(--ion-color-primary);
    --indicator-box-shadow: 0;
    --padding-top: 0.86em;
    --padding-bottom: 0.86em;

    font-size: 0.875em;

    &::part(native) {
      white-space: pre-line;
    }
  }

  &__action-container {
    @extend %page-content-spacing;

    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
  }

  &__action-item {
    height: 0;
    flex: 1;
    position: relative;
  }

  &__info-action {
    --padding-start: 5px;
    --padding-end: 5px;
    --padding-top: 5px;
    --padding-bottom: 5px;

    width: px-to-rem(30px);
    height: px-to-rem(30px);
    position: absolute;
    top: px-to-rem(6px);
    right: px-to-rem(5px);
    margin: 0;
    background-color: transparent;

    &::part(native) {
      background-color: transparent;
      z-index: 3;

      &::after {
        z-index: -2;
      }

      &::before {
        z-index: -1;
        content: '';
        display: block;
        background-color: var(--cetim-color-light);
        position: absolute;
        top: px-to-rem(5px);
        left: px-to-rem(5px);
        border-radius: 50%;
        width: px-to-rem(20px);
        height: px-to-rem(20px);
      }
    }
  }

  &__info-action-icon {
    font-size: px-to-rem(18px);
    margin: 0;
  }
}
</style>
