<template>
  <IonSelect
    class="dropdown-select"
    :interface="interface"
    :placeholder="label"
    :cancel-text="$t('cancel')"
    @ionChange="emitNewValue"
    :value="modelValue"
  >
    <IonSelectOption v-for="(option, index) in options" :value="option.value" :key="`${label}-options-${index}`">
      {{ option.label }}
    </IonSelectOption>
  </IonSelect>
</template>

<script setup lang="ts">
import { IonSelect, IonSelectOption } from '@ionic/vue';
import SelectOption from '@/types/SelectOption';

withDefaults(
  defineProps<{
    modelValue?: any;
    label: string;
    options: Array<SelectOption>;
    interface?: 'action-sheet' | 'popover' | undefined;
  }>(),
  {
    interface: 'action-sheet',
  }
);

const emit = defineEmits(['update:modelValue']);

const emitNewValue = (event: any) => {
  emit('update:modelValue', event.detail.value);
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/placeholders/bold' as *;

.dropdown-select {
  @extend %bold;

  --placeholder-opacity: 1;
  --padding-top: 1.19em;
  --padding-bottom: 1.19em;

  width: 100%;
  border-top: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));

  &::part(placeholder),
  &::part(text) {
    text-align: center;
    font-size: 1.125em;
  }

  &::part(icon) {
  }
}
</style>
