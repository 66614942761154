export default Object.freeze({
  CoefficientInch: 25.4,
  CoefficientFtlbf: 0.737562149,
  Coefficient1: 1 / (2 * Math.PI),
  Coefficient2: 0.577,
  CalculD2: 0.6495,
  CalculD3: 1.2268,
  CalculDi1: 1.085894,
  CalculDi2: 0.153965,
  CalculDw1: 1.4045,
  CalculDw2: 0.31954,
  CalculDequ: 0.9382,
});
