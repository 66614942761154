<template>
  <IonPage class="compute-page">
    <IonContent class="compute-page__ion-content">
      <div class="compute-page__content-container">
        <BaseButton class="compute-page__close" color="light" @click="goBack">
          <BaseIcon class="compute-page__close-icon" :icon="Icons.Cross"></BaseIcon>
          <span class="compute-page__close-label">{{ $t('wizard.close') }}</span>
        </BaseButton>

        <BaseStepper class="compute-page__stepper" :total="5" :current="currentStep"></BaseStepper>

        <p class="compute-page__header">
          <span class="compute-page__title">
            <span class="compute-page__tag">
              {{ currentStep }}
            </span>
            {{ title }}
          </span>
          <span v-if="subtitle" class="compute-page__subtitle">
            {{ subtitle }}
          </span>
        </p>

        <div class="compute-page__slot-container">
          <slot />
        </div>

        <div class="compute-page__navigation">
          <BaseButton
            v-if="currentStep > 1"
            rounded
            color="medium"
            class="compute-page__navigator"
            :icon="Icons.BackArrow"
            icon-slot="start"
            @click="prevStep"
          >
            {{ $t('wizard.previous') }}
          </BaseButton>
          <BaseButton rounded class="compute-page__navigator" :icon="Icons.NextArrow" @click="nextStep">
            {{ nextButtonLabel }}
          </BaseButton>
        </div>
      </div>
    </IonContent>
  </IonPage>
</template>

<script setup lang="ts">
import { computed, defineEmits, withDefaults } from 'vue';
import { IonPage, IonContent, useIonRouter } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { RoutePath } from '@/enums/Router';
import BaseStepper from '@/components/BaseStepper.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import { Icons } from '@/enums/Icons';
import { useComputeWizardStore } from '@/store/computeWizard';

import useSizeStoreValidation from '@/composables/ComputeWizard/Validation/useSizeStoreValidation';
import useSurfaceStoreValidation from '@/composables/ComputeWizard/Validation/useSurfaceStoreValidation';
import useFrictionStoreValidation from '@/composables/ComputeWizard/Validation/useFrictionStoreValidation';
import useQualityStoreValidation from '@/composables/ComputeWizard/Validation/useQualityStoreValidation';
import useClampingStoreValidation from '@/composables/ComputeWizard/Validation/useClampingStoreValidation';

const { t } = useI18n();
const router = useIonRouter();
const computeWizardStore = useComputeWizardStore();
const sizeStoreValidation = useSizeStoreValidation();
const surfaceStoreValidation = useSurfaceStoreValidation();
const frictionStoreValidation = useFrictionStoreValidation();
const qualityStoreValidation = useQualityStoreValidation();
const clampingStoreValidation = useClampingStoreValidation();

const props = withDefaults(
  defineProps<{
    currentStep: number;
    title: string;
    subtitle?: string;
    prevUrl?: string;
    nextUrl?: string;
    updateFinalResultOnClose?: boolean;
  }>(),
  {
    currentStep: 1,
    updateFinalResultOnClose: false,
  }
);

// type-based
const emit = defineEmits<{
  (e: 'onPreStep'): void;
  (e: 'onNextStep'): void;
  (e: 'onClose'): void;
}>();

const isLastStep = computed(() => {
  return props.currentStep >= 5;
});

const nextButtonLabel = computed(() => {
  return isLastStep.value ? t('wizard.submit') : t('wizard.next');
});

const checkStepValidation = async (): Promise<boolean> => {
  const currentStore = [
    sizeStoreValidation,
    surfaceStoreValidation,
    frictionStoreValidation,
    qualityStoreValidation,
    clampingStoreValidation,
  ][props.currentStep - 1];

  return currentStore.validateTheStep();
};

const prevStep = async (): Promise<void> => {
  if (!(await checkStepValidation())) {
    return;
  }

  if (props.prevUrl) {
    emit('onPreStep');
    router.back();
  }
};

const nextStep = async (): Promise<void> => {
  if (!(await checkStepValidation())) {
    return;
  }

  if (props.nextUrl) {
    emit('onNextStep');
    router.push(props.nextUrl);
  }
};

const goBack = async (): Promise<void> => {
  if (!(await checkStepValidation())) {
    return;
  }

  if (computeWizardStore.isValid || props.updateFinalResultOnClose) {
    computeWizardStore.updateFinalResult();
  }

  emit('onClose');
  router.push(RoutePath.HOME);
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/functions/px-to-rem' as *;
@use '@/assets/scss/lib/mixins/sr-only' as *;
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/placeholders/bold' as *;

.compute-page {
  &__stepper {
    margin-top: px-to-rem(55px);
  }

  &__ion-content,
  &__content-container {
    display: flex;
    flex-direction: column;
  }

  &__ion-content {
    @extend %page;
  }

  &__content-container {
    @extend %page-content-container;
  }

  &__stepper {
    @extend %page-content-spacing;

    margin-bottom: 0;
  }

  &__header {
    @extend %page-content-spacing;
  }

  &__content {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
  }

  &__navigation {
    @extend %page-content-spacing;

    margin-top: auto;
    display: flex;
    gap: 1.25em;
    left: 0.875em;
    right: 0.875em;
  }

  &__navigator {
    margin-top: px-to-rem(20px);
    margin-bottom: px-to-rem(20px);
    flex-grow: 1;
    flex-basis: 50%;
  }

  &__close {
    position: absolute;
    right: 0;
    margin: 0;
    height: px-to-rem(55px);
    width: px-to-rem(55px);
  }

  &__close-label {
    @include sr-only();
  }

  &__close-icon {
    color: black;
    font-size: px-to-rem(16px);
  }

  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--ion-color-dark);
    color: var(--ion-color-light);
    width: 1.5em;
    height: 1.5em;
    font-size: 0.67em;
    border-radius: 50%;
    margin-right: 0.5em;
  }

  &__title {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 1.125em;
    text-transform: uppercase;
  }

  &__subtitle {
    display: block;
    text-align: center;
    @extend %bold;
    font-size: 0.875em;
  }
}
</style>
