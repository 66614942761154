<template>
  <IonPage class="settings-page">
    <IonContent class="settings-page__content" color="primary" :forceOverscroll="false">
      <div class="settings-page__content-container">
        <header class="settings-page__header">
          <BaseButton class="settings-page__close-button" color="primary" @click="goBack">
            <BaseIcon class="settings-page__close-button-icon" :icon="Icons.Cross"></BaseIcon>
          </BaseButton>
        </header>

        <IonText class="settings-page__title-container">
          <h1 class="settings-page__title">{{ $t('settings.page.title') }}</h1>
        </IonText>

        <IonSegment
          class="settings-page__lang-segment"
          @ionChange="onLangSegmentChange($event)"
          :value="langSettings.currentLocale()"
        >
          <IonSegmentButton
            v-for="(lang, index) of langList"
            :key="index"
            :class="`settings-page__lang-segment-button settings-page__lang-segment-button--${lang.Iso}`"
            :value="lang.Iso"
          >
            <IonLabel :class="`settings-page__lang-segment-label settings-page__lang-segment-label--${lang.Iso}`">
              {{ lang.Label }}
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>

        <IonList class="settings-page__field-list">
          <IonItem class="settings-page__field-item" color="primary" lines="full">
            <div class="settings-page__field-label-wrapper">
              <IonLabel class="settings-page__field-label">{{ $t('settings.fields.clamping_level.label') }}</IonLabel>
              <IonText class="settings-page__field-hint">{{ $t('settings.fields.clamping_level.hint') }}</IonText>
            </div>
            <IonInput
              class="settings-page__field-input"
              @ionChange="onClampingLevelChange($event)"
              :value="displayedClampingLevel"
              min="1"
              max="100"
              type="number"
              inputMode="decimal"
              pattern="[0-9]*"
              step="1"
            ></IonInput>
            <IonText>%</IonText>
          </IonItem>

          <IonItem class="settings-page__field-item" color="primary" lines="full">
            <div class="settings-page__field-label-wrapper">
              <IonLabel class="settings-page__field-label">
                {{ unitValue }}
              </IonLabel>
              <IonText class="settings-page__field-hint settings-page__field-hint--unit">
                {{ $t('settings.fields.unit.hint') }}
              </IonText>
            </div>
            <IonToggle
              class="settings-page__field-toggle"
              @ionChange="onUnitToggleChange($event)"
              :value="calculationSettings.unit.value"
              :checked="calculationSettings.unit.value === Unit.FootPoundForce"
            ></IonToggle>
          </IonItem>
        </IonList>
      </div>
    </IonContent>
  </IonPage>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import {
  IonContent,
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonToggle,
  useIonRouter,
} from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { useComputeWizardStore } from '@/store/computeWizard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Lang } from '@/enums/Lang';
import { Unit } from '@/enums/Unit';
import { Icons } from '@/enums/Icons';
import { Platform } from '@/enums/Platform';
import { RouteName } from '@/enums/Router';
import useLangSettings from '@/composables/useLangSettings';
import useCalculationSettings from '@/composables/useCalculationSettings';
import BaseButton from '@/components/BaseButton';
import BaseIcon from '@/components/BaseIcon';

const computeWizardStore = useComputeWizardStore();
const langSettings = useLangSettings();
const calculationSettings = useCalculationSettings();

const displayedClampingLevel = ref<number>(calculationSettings.clampingLevel.value);

const { t } = useI18n();

const langList = [
  {
    Iso: Lang.En.Iso,
    Label: Lang.En.ShortLabel,
  },
  {
    Iso: Lang.Fr.Iso,
    Label: Lang.Fr.ShortLabel,
  },
];

const router = useIonRouter();

const unitValue = computed(() => {
  return t('settings.fields.unit.label', { unit: calculationSettings.unit.value });
});

const onLangSegmentChange = (event: CustomEvent) => {
  langSettings.updateLangIso(event.detail.value);
};

const onClampingLevelChange = (event: CustomEvent) => {
  let value = parseInt(event.detail.value);

  if (!value || value < 1) {
    value = 1;
  }

  calculationSettings.clampingLevel.value = value > 100 ? 100 : value;
};

const onUnitToggleChange = (event: CustomEvent) => {
  calculationSettings.unit.value = event.detail.checked ? Unit.FootPoundForce : Unit.NewtonMeter;
};

const goBack = () => {
  computeWizardStore.updateFinalResult();
  if (Capacitor.getPlatform() === Platform.WEB) {
    router.push({ name: RouteName.HOME });
  } else {
    router.back();
  }
};

watch(calculationSettings.unit, () => {
  if (computeWizardStore.isValid) {
    computeWizardStore.updateFinalResult();
  }
});

onMounted(() => {
  if (Capacitor.getPlatform() === Platform.IOS) {
    StatusBar.setStyle({ style: Style.Dark });
  }
});

onUnmounted(() => {
  if (Capacitor.getPlatform() === Platform.IOS) {
    StatusBar.setStyle({ style: Style.Light });
  }
});
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/functions/px-to-em' as *;
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.settings-page {
  @extend %page;

  &__content-container {
    @extend %page-content-container;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1em;
  }

  &__close-button {
    margin: 0;
    height: px-to-rem(55px);
    width: px-to-rem(55px);
  }

  &__close-button-icon {
    font-size: px-to-em(16px);
  }

  &__title-container {
    @extend %page-content-small-spacing;
  }

  &__title {
    text-transform: uppercase;
    text-align: center;
    font-size: px-to-rem(20px);
    margin: 0;
  }

  &__lang-segment {
    --background: var(--ion-color-light);
    --color: var(--ion-color-primary);

    max-width: px-to-em(190px);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: px-to-em(30px);
    padding: 1px 3px;
  }

  &__lang-segment-button {
    --color: var(--ion-color-primary);
    --color-checked: var(--ion-color-light);
    --indicator-color: var(--ion-color-primary);
    --indicator-box-shadow: 0;

    font-size: 0.875em;
  }

  &__field-item {
    border-color: #fff;
  }

  &__lang-segment-label {
    padding: px-to-em(10px) 0;
    text-transform: uppercase;
  }

  &__field-label-wrapper {
    padding: px-to-em(13px) 0;
  }

  &__field-input {
    --padding-end: 0;

    text-align: right;
    align-self: center;
    align-items: stretch;
    margin-left: 1em;
    margin-right: 0.2em;
    height: 60%;
    background-color: #ac1e2c;
    border-radius: 0.5em;
  }

  &__field-hint {
    color: var(--ion-color-danger-tint);
    font-size: px-to-em(16px);
  }

  &__field-toggle {
    --background-checked: #ac1e2c;
    --background: var(--ion-color-primary-shade);
    --handle-background-checked: #6f111b;
    --handle-background: #ffffff;

    margin-left: auto;
  }
}
</style>
