import { defineStore } from 'pinia';
import { ClampingTool } from '@/enums/Clamping';
import { ClampingToolValue, clampingToolValues } from '@/data/ClampingValue';
import useFindEnumKeyFromValue from '@/composables/useFindEnumKeyFromValue';
import { useI18n } from 'vue-i18n';

export interface ClampingStepStore {
  tool: ClampingTool | undefined;
  specificSetting: number | undefined;
}

export const useClampingStepStore = defineStore('clampingStep', {
  state: (): ClampingStepStore => {
    return {
      tool: ClampingTool.TriggerTorqueWrench15,
      specificSetting: undefined,
    };
  },
  getters: {
    toolObject(): ClampingToolValue {
      return clampingToolValues.filter((item) => {
        return item.tool === this.tool;
      })[0];
    },
    clampingValue(): number {
      if (this.toolObject) {
        return this.toolObject.value / 100;
      }

      if (this.specificSetting) {
        return this.specificSetting / 100;
      }

      return -1;
    },
    stepButtonValue(): string {
      const { t } = useI18n();

      if (this.tool) {
        return t(`clamping.tool_values.${useFindEnumKeyFromValue(ClampingTool, this.tool)}`);
      }

      return t('clamping.specific_values', { value: this.specificSetting });
    },
  },
  actions: {
    resetValues(): void {
      this.tool = ClampingTool.TriggerTorqueWrench15;
      this.specificSetting = undefined;
    },
    onToolUpdate(): void {
      if (this.tool?.toString() !== '') {
        this.specificSetting = undefined;
      }
    },
    onSpecificUpdate(): void {
      if (this.specificSetting?.toString() === '') {
        if (this.tool?.toString() === '') {
          this.tool = ClampingTool.TriggerTorqueWrench15;
        }
      } else {
        this.tool = undefined;
      }
    },
  },
});
