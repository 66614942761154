import { SizeUnit } from '@/enums/Size';

import CalculationConstant from '@/data/CalculationConstant';

export interface SizeValue {
  unit: SizeUnit;
  key: string;
  value: number;
}

export interface PitchMetricValue {
  big: number;
  slim: number;
  super_slim: number | undefined;
}

export interface PitchImperialValue {
  unc: number | undefined;
  unf: number | undefined;
  eight_un: number | undefined;
}

export interface SizeMetricValue extends SizeValue {
  pitch: PitchMetricValue;
}

export interface SizeImperialValue extends SizeValue {
  pitch: PitchImperialValue;
}

export const defaultSizePitchValue = 10;

export const metricValues: Array<SizeMetricValue> = [
  {
    unit: SizeUnit.Metric,
    key: 'M8',
    value: 8,
    pitch: {
      big: 1.25,
      slim: 1,
      super_slim: 0.75,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M64',
    value: 64,
    pitch: {
      big: 6,
      slim: 4,
      super_slim: 3,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M60',
    value: 60,
    pitch: {
      big: 5.5,
      slim: 4,
      super_slim: 3,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M6',
    value: 6,
    pitch: {
      big: 1,
      slim: 0.75,
      super_slim: undefined,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M56',
    value: 56,
    pitch: {
      big: 5.5,
      slim: 4,
      super_slim: 3,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M52',
    value: 52,
    pitch: {
      big: 5,
      slim: 4,
      super_slim: 3,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M5',
    value: 5,
    pitch: {
      big: 0.8,
      slim: 0.5,
      super_slim: undefined,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M48',
    value: 48,
    pitch: {
      big: 5,
      slim: 4,
      super_slim: 3,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M45',
    value: 45,
    pitch: {
      big: 4.5,
      slim: 4,
      super_slim: 3,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M4',
    value: 4,
    pitch: {
      big: 0.7,
      slim: 0.5,
      super_slim: undefined,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M39',
    value: 39,
    pitch: {
      big: 4,
      slim: 3,
      super_slim: 2,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M36',
    value: 36,
    pitch: {
      big: 4,
      slim: 3,
      super_slim: 2,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M33',
    value: 33,
    pitch: {
      big: 3.5,
      slim: 2,
      super_slim: 1.5,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M30',
    value: 30,
    pitch: {
      big: 3.5,
      slim: 2,
      super_slim: 1.5,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M3',
    value: 3,
    pitch: {
      big: 0.5,
      slim: 0.35,
      super_slim: undefined,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M27',
    value: 27,
    pitch: {
      big: 3,
      slim: 2,
      super_slim: 1.25,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M24',
    value: 24,
    pitch: {
      big: 3,
      slim: 2,
      super_slim: 1.5,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M22',
    value: 22,
    pitch: {
      big: 2.5,
      slim: 2,
      super_slim: 1.5,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M20',
    value: 20,
    pitch: {
      big: 2.5,
      slim: 2,
      super_slim: 1.5,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M2.5',
    value: 2.5,
    pitch: {
      big: 0.45,
      slim: 0.35,
      super_slim: undefined,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M2',
    value: 2,
    pitch: {
      big: 0.4,
      slim: 0.25,
      super_slim: undefined,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M18',
    value: 18,
    pitch: {
      big: 2.5,
      slim: 2,
      super_slim: 1.5,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M16',
    value: 16,
    pitch: {
      big: 2,
      slim: 1.5,
      super_slim: 1,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M14',
    value: 14,
    pitch: {
      big: 2,
      slim: 1.5,
      super_slim: 1,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M12',
    value: 12,
    pitch: {
      big: 1.75,
      slim: 1.5,
      super_slim: 1.25,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M10',
    value: 10,
    pitch: {
      big: 1.5,
      slim: 1.25,
      super_slim: 1,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M1.6',
    value: 1.6,
    pitch: {
      big: 0.35,
      slim: 0.2,
      super_slim: undefined,
    },
  },
  {
    unit: SizeUnit.Metric,
    key: 'M42',
    value: 42,
    pitch: {
      big: 4.5,
      slim: 4,
      super_slim: 3,
    },
  },
];

export const imperialValues: Array<SizeImperialValue> = [
  {
    unit: SizeUnit.Imperial,
    key: '1/4"',
    value: (1 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 20,
      unf: CalculationConstant.CoefficientInch / 28,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '5/16"',
    value: (5 / 16) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 18,
      unf: CalculationConstant.CoefficientInch / 24,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '3/8"',
    value: (3 / 8) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 16,
      unf: CalculationConstant.CoefficientInch / 24,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '7/16"',
    value: (7 / 16) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 14,
      unf: CalculationConstant.CoefficientInch / 20,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1/2"',
    value: (1 / 2) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 13,
      unf: CalculationConstant.CoefficientInch / 20,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '9/16"',
    value: (9 / 16) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 12,
      unf: CalculationConstant.CoefficientInch / 18,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '5/8"',
    value: (5 / 8) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 11,
      unf: CalculationConstant.CoefficientInch / 18,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '3/4"',
    value: (3 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 10,
      unf: CalculationConstant.CoefficientInch / 16,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '7/8"',
    value: (7 / 8) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 9,
      unf: CalculationConstant.CoefficientInch / 14,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"',
    value: CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 8,
      unf: CalculationConstant.CoefficientInch / 14,
      eight_un: undefined,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"1/8',
    value: (1 + 1 / 8) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 7,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"1/4',
    value: (1 + 1 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 7,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"3/8',
    value: (1 + 3 / 8) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 6,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"1/2',
    value: (1 + 1 / 2) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 6,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"5/8',
    value: (1 + 5 / 8) * CalculationConstant.CoefficientInch,
    pitch: { unc: undefined, unf: undefined, eight_un: CalculationConstant.CoefficientInch / 8 },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"3/4',
    value: (1 + 3 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 5,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '1"7/8',
    value: (1 + 7 / 8) * CalculationConstant.CoefficientInch,
    pitch: { unc: undefined, unf: undefined, eight_un: CalculationConstant.CoefficientInch / 8 },
  },
  {
    unit: SizeUnit.Imperial,
    key: '2"',
    value: 2 * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4.5,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '2"1/4',
    value: (2 + 1 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4.5,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '2"1/2',
    value: (2 + 1 / 2) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '2"3/4',
    value: (2 + 3 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '3"',
    value: 3 * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '3"1/4',
    value: (3 + 1 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '3"1/2',
    value: (3 + 1 / 2) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '3"3/4',
    value: (3 + 3 / 4) * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
  {
    unit: SizeUnit.Imperial,
    key: '4"',
    value: 4 * CalculationConstant.CoefficientInch,
    pitch: {
      unc: CalculationConstant.CoefficientInch / 4,
      unf: CalculationConstant.CoefficientInch / 12,
      eight_un: CalculationConstant.CoefficientInch / 8,
    },
  },
];
