import useAlertOnEvent from '@/composables/useAlertOnEvent';
import { useI18n } from 'vue-i18n';
import { ConditionType } from '@/enums/Condition';
import { useFrictionStepStore } from '@/store/frictionStep';
import { InvalidFormatType } from '@/enums/InvalidFormat';

export default () => {
  const alertManager = useAlertOnEvent();
  const frictionStepStore = useFrictionStepStore();
  const { t } = useI18n();

  const validateSpecificValues = async (): Promise<boolean> => {
    if (
      frictionStepStore.specificCoefficientMin !== undefined &&
      frictionStepStore.specificCoefficientMax !== undefined
    ) {
      if (
        frictionStepStore.specificCoefficientMin < frictionStepStore.specificCoefficientMinMinimumValue ||
        frictionStepStore.specificCoefficientMin > frictionStepStore.specificCoefficientMinMaximumValue
      ) {
        await alertManager.fireConditionAlert({
          field_name: t('friction.coefficient.min_input_label'),
          current_value: frictionStepStore.specificCoefficientMin,
          conditionType: ConditionType.BetweenStrict,
          min: frictionStepStore.specificCoefficientMinMinimumValue,
          max: frictionStepStore.specificCoefficientMinMaximumValue,
        });
        return false;
      }
      if (
        frictionStepStore.specificCoefficientMax < frictionStepStore.specificCoefficientMaxMinimumValue ||
        frictionStepStore.specificCoefficientMax > frictionStepStore.specificCoefficientMaxMaximumValue
      ) {
        await alertManager.fireConditionAlert({
          field_name: t('friction.coefficient.max_input_label'),
          current_value: frictionStepStore.specificCoefficientMax,
          conditionType: ConditionType.BetweenStrict,
          min: frictionStepStore.specificCoefficientMaxMinimumValue,
          max: frictionStepStore.specificCoefficientMaxMaximumValue,
        });
        return false;
      }
    }

    return true;
  };

  const validateDecimalValues = async (): Promise<boolean> => {
    if (
      frictionStepStore.specificCoefficientMin !== undefined &&
      frictionStepStore.specificCoefficientMax !== undefined
    ) {
      const coefficientMinDecimals = frictionStepStore.specificCoefficientMin.toString().split('.')[1];
      const coefficientMaxDecimals = frictionStepStore.specificCoefficientMax.toString().split('.')[1];
      const acceptedDecimalCount = 3;

      if (coefficientMinDecimals && coefficientMinDecimals.length > acceptedDecimalCount) {
        await alertManager.fireInvalidFormat({
          field_name: t('friction.coefficient.min_input_label'),
          current_value: frictionStepStore.specificCoefficientMin,
          invalidFormatType: InvalidFormatType.MaxDecimal,
          maxDecimal: acceptedDecimalCount,
        });
        return false;
      }

      if (coefficientMaxDecimals && coefficientMaxDecimals.length > acceptedDecimalCount) {
        await alertManager.fireInvalidFormat({
          field_name: t('friction.coefficient.max_input_label'),
          current_value: frictionStepStore.specificCoefficientMax,
          invalidFormatType: InvalidFormatType.MaxDecimal,
          maxDecimal: acceptedDecimalCount,
        });
        return false;
      }
    }

    return true;
  };

  const validateTheStep = async (): Promise<boolean> => {
    return (await validateSpecificValues()) && (await validateDecimalValues());
  };

  return {
    validateTheStep,
    validateSpecificValues,
    validateDecimalValues,
  };
};
