<template>
  <ComputeWizardStepContainer class="compute-wizard-surface">
    <IonText
      class="compute-wizard-surface__label compute-wizard-surface__label--bold compute-wizard-surface__label--alone"
    >
      {{ externalDiameterLabel }}
    </IonText>

    <SegmentSelect
      @on-change="onGenericTypeChange"
      :options="surfaceGenericTypeOptions"
      v-model="surfaceStepStore.genericType"
    />

    <div class="compute-wizard-surface__label-container">
      <IonText class="compute-wizard-surface__label compute-wizard-surface__label--bold">
        {{ internalDiameterLabel }}
      </IonText>
      <IonText class="compute-wizard-surface__label compute-wizard-surface__label--description">
        {{ $t('surface.internal_description') }}
      </IonText>
    </div>

    <img class="compute-wizard-surface__image" :src="Bearing1" />

    <img class="compute-wizard-surface__image" :src="Bearing2" />

    <ComputeWizardInputAccordion
      class="compute-wizard-surface__input-accordion"
      :title="$t('surface.specific_settings.accordion_label')"
    >
      <IonItem lines="full" class="compute-wizard-surface__input-accordion-item" slot="content">
        <IonLabel class="compute-wizard-surface__input-label" position="stacked">{{
          $t('surface.specific_settings.inner_bearing_diameter_input_label')
        }}</IonLabel>
        <IonInput
          class="compute-wizard-surface__input"
          type="number"
          inputMode="decimal"
          pattern="[0-9]*"
          v-model="surfaceStepStore.specificInnerBearingDiameter"
          :step="surfaceStepStore.specificInnerBearingDiameterStep"
          :min="surfaceStepStore.specificInnerBearingDiameterMinValue"
          :max="surfaceStepStore.specificInnerBearingDiameterMaxValue"
          :placeholder="$t('surface.specific_settings.inner_bearing_diameter_input_placeholder')"
          @ionChange="onUpdateInnerBearingDiameterChange"
        />

        <BaseButton
          class="compute-wizard-surface__input-action"
          slot="end"
          color="light"
          @click="onInputInfoClick($t('surface.alert.inner_bearing_message'))"
        >
          <BaseIcon class="compute-wizard-surface__input-action-icon" :icon="Icons.Info"></BaseIcon>
        </BaseButton>
      </IonItem>
      <IonItem lines="full" class="compute-wizard-surface__input-accordion-item" slot="content">
        <IonLabel class="compute-wizard-surface__input-label" position="stacked">{{
          $t('surface.specific_settings.external_bearing_diameter_input_label')
        }}</IonLabel>
        <IonInput
          class="compute-wizard-surface__input"
          type="number"
          inputMode="decimal"
          pattern="[0-9]*"
          :step="surfaceStepStore.specificExternalBearingDiameterStep"
          :min="surfaceStepStore.specificExternalBearingDiameterMinValue"
          :max="surfaceStepStore.specificExternalBearingDiameterMaxValue"
          v-model="surfaceStepStore.specificExternalBearingDiameter"
          enterkeyhint="done"
          :placeholder="$t('surface.specific_settings.external_bearing_diameter_input_placeholder')"
          @ionChange="onExternalBearingDiameterChange"
          @keyup.enter="$emit('requestNextStep')"
        />

        <BaseButton
          class="compute-wizard-surface__input-action"
          slot="end"
          color="light"
          @click="onInputInfoClick($t('surface.alert.external_bearing_message'))"
        >
          <BaseIcon class="compute-wizard-surface__input-action-icon" :icon="Icons.Info"></BaseIcon>
        </BaseButton>
      </IonItem>
    </ComputeWizardInputAccordion>
  </ComputeWizardStepContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { IonText, IonItem, IonLabel, IonInput, alertController } from '@ionic/vue';
import { SurfaceGenericType } from '@/enums/Surface';
import { Icons } from '@/enums/Icons';
import { TranslateElement } from '@/types/TranslateElement';
import { useSurfaceStepStore } from '@/store/surfaceStep';
import useTranslateEnums from '@/composables/useTranslateEnums';
import ComputeWizardStepContainer from '@/components/ComputeWizard/ComputeWizardStepContainer.vue';
import SegmentSelect from '@/components/SegmentSelect.vue';
import ComputeWizardInputAccordion from '@/components/ComputeWizard/ComputeWizardInputAccordion.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseIcon from '@/components/BaseIcon.vue';
import Bearing1 from '@/assets/images/step-schemas/bearing-surface-1.png';
import Bearing2 from '@/assets/images/step-schemas/bearing-surface-2.png';

const { translate } = useTranslateEnums();

defineEmits(['requestNextStep']);

const surfaceStepStore = useSurfaceStepStore();

const surfaceGenericTypeOptions = computed((): Array<TranslateElement> => {
  return translate(SurfaceGenericType, 'surface.diameters');
});

const { t } = useI18n();

const externalDiameterLabel = computed((): string => {
  return `${t('surface.external_diameter', { value: surfaceStepStore.roundedExternalBearingDiameter })}mm`;
});
const internalDiameterLabel = computed((): string => {
  return `${t('surface.internal_diameter', { value: surfaceStepStore.roundedInnerBearingDiameter })}mm`;
});

const onInputInfoClick = async (header: string) => {
  const alert = await alertController.create({
    header,
    subHeader: undefined,
    message: undefined,
    buttons: [t('close')],
  });

  await alert.present();
};

const onGenericTypeChange = () => {
  surfaceStepStore.onGenericTypeUpdate();
};

const onUpdateInnerBearingDiameterChange = () => {
  surfaceStepStore.onSpecificInnerBearingDiameterUpdate();
};

const onExternalBearingDiameterChange = () => {
  surfaceStepStore.onSpecificExternalBearingDiameterUpdate();
};
</script>

<style scoped lang="scss">
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/placeholders/bold' as *;
@use '@/assets/scss/lib/functions/px-to-em' as *;
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.compute-wizard-surface {
  &__label-container {
    @extend %page-content-spacing;

    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 1em;
    margin-bottom: -1em;
  }

  &__label {
    align-self: flex-start;
    margin-left: 0.5em;
    margin-right: 0.5em;

    &--bold {
      @extend %bold;
    }

    &--alone {
      @extend %page-content-spacing;
    }

    &--description {
      font-size: 0.875em;
      margin-top: 0.25em;
    }
  }

  &__input-accordion-item {
    --padding-top: #{px-to-em(8px)};
    --padding-bottom: #{px-to-em(8px)};
  }

  &__input {
    --min-height: unset;
    --padding-left: #{px-to-em(2px)};
  }

  &__input-label {
    margin-top: px-to-rem(4px);
    margin-bottom: 0;
  }

  &__input-action {
    right: 0;
    height: 100%;
    margin: 0;
  }

  &__input-action-icon {
    font-size: px-to-rem(27px);
    margin: 0 px-to-rem(3px);
  }

  &__image {
    margin: px-to-em(20px) auto;
    width: 70%;
    object-fit: contain;
  }
}
</style>
