import { defineStore } from 'pinia';
import { QualityMaterial, GradeVariant } from '@/enums/Quality';
import {
  carbonSteelVariant,
  defaultValue,
  QualityMaterialVariant,
  saeGradeVariant,
  stainlessSteelVariant,
} from '@/data/QualityValues';
import CalculationConstant from '@/data/CalculationConstant';
import { useSizeStepStore } from '@/store/sizeStep';
import { useI18n } from 'vue-i18n';

export interface QualityStepStore {
  selectedMaterial: QualityMaterial | undefined;
  selectedGradeVariant: GradeVariant | string | undefined;
  specificValue: number | undefined;
}

export const useQualityStepStore = defineStore('qualityStep', {
  state: (): QualityStepStore => {
    return {
      selectedMaterial: QualityMaterial.CarbonSteel,
      selectedGradeVariant: GradeVariant.Value_8_8,
      specificValue: undefined,
    };
  },
  getters: {
    displayedMaterial(): string | undefined {
      if (this.specificValue === undefined) {
        return this.selectedMaterial;
      }

      return undefined;
    },
    qualityMaterialValues(): Array<QualityMaterialVariant> | undefined {
      if (this.selectedMaterial) {
        if (this.selectedMaterial === QualityMaterial.CarbonSteel) {
          return carbonSteelVariant;
        } else if (this.selectedMaterial === QualityMaterial.StainlessSteel) {
          return stainlessSteelVariant;
        } else {
          return saeGradeVariant;
        }
      }

      return undefined;
    },
    qualityObject(): QualityMaterialVariant | undefined {
      if (this.qualityMaterialValues && this.selectedGradeVariant) {
        return this.qualityMaterialValues.find((item: QualityMaterialVariant) => {
          return item.variant === this.selectedGradeVariant;
        });
      }

      return undefined;
    },
    qualityObjectMappedValue(): number | undefined {
      const qualityObject = this.qualityObject;

      if (!qualityObject) {
        return undefined;
      }

      if (qualityObject.variant === GradeVariant.Grade_2) {
        if (qualityObject.value <= (3 / 4) * CalculationConstant.CoefficientInch) {
          return 393;
        } else {
          return 248.2;
        }
      }

      if (qualityObject.variant === GradeVariant.Grade_5) {
        if (qualityObject.value <= CalculationConstant.CoefficientInch) {
          return 634.3;
        } else {
          return 558.5;
        }
      }

      if (qualityObject.variant === GradeVariant.Value_8_8) {
        const storeSizeValue = useSizeStepStore().sizeValue;
        if (storeSizeValue > 16) {
          return 660;
        } else {
          return 640;
        }
      }

      return qualityObject.value;
    },
    qualityValue(): number {
      return this.qualityObjectMappedValue || this.specificValue || 0;
    },
    stepButtonValue(): string {
      const { t } = useI18n();

      if (this.selectedMaterial && this.selectedGradeVariant) {
        return t(`quality.grade_variant_values.${this.selectedMaterial}.${this.selectedGradeVariant}`);
      }

      return t('quality.specific_values', { value: this.specificValue });
    },
  },
  actions: {
    resetValues(): void {
      this.selectedMaterial = QualityMaterial.CarbonSteel;
      this.selectedGradeVariant = GradeVariant.Value_8_8;
      this.specificValue = undefined;
    },
    setSelectedMaterial(newSelectedMaterial: QualityMaterial | undefined): void {
      this.selectedMaterial = newSelectedMaterial;

      if (this.selectedMaterial) {
        this.selectedGradeVariant = defaultValue(this.selectedMaterial);
        this.specificValue = undefined;
      }
    },
    setSelectedGradeVariant(newSelectedGradeVariant: GradeVariant | string | undefined): void {
      this.selectedGradeVariant = newSelectedGradeVariant;
    },
    setSpecificValue(newSpecificValue: any | undefined): void {
      const typeOfNewValue = typeof newSpecificValue;
      if ((typeOfNewValue === 'number' || typeOfNewValue === 'string') && newSpecificValue !== '') {
        this.specificValue = parseFloat(newSpecificValue);
        this.selectedMaterial = undefined;
        this.selectedGradeVariant = undefined;
      } else {
        this.specificValue = undefined;
        this.selectedMaterial = QualityMaterial.CarbonSteel;
        this.selectedGradeVariant = GradeVariant.Value_8_8;
      }
    },
  },
});
