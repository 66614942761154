<template>
  <IonButton :class="buttonClasses" :color="color" :expand="expand" :href="href" :router-link="routerLink" :fill="fill">
    <slot></slot>
    <BaseIcon :class="iconClass" v-if="icon" :icon="icon" :slot="iconSlot" />
  </IonButton>
</template>

<script setup lang="ts">
import { IonButton } from '@ionic/vue';
import BaseIcon from '@/components/BaseIcon.vue';
import { Icons } from '@/enums/Icons';

const BLOCK_SELECTOR = 'base-button';

const props = withDefaults(
  defineProps<{
    color?: 'primary' | 'light' | 'medium' | 'dark';
    expand?: 'block' | 'full' | undefined;
    rounded?: boolean;
    fill?: 'clear' | 'default' | 'solid';
    href?: string;
    routerLink?: string;
    icon?: typeof Icons | string | undefined;
    iconSlot?: 'end' | 'start' | undefined;
  }>(),
  {
    color: 'primary',
    rounded: false,
    fill: undefined,
    iconSlot: 'end',
  }
);

const buttonClasses = [
  BLOCK_SELECTOR,
  `${BLOCK_SELECTOR}--${props.fill}`,
  `${BLOCK_SELECTOR}--${props.color}`,
  {
    [`${BLOCK_SELECTOR}--rounded`]: props.rounded,
  },
];

const iconClass = [`${BLOCK_SELECTOR}__icon`, `${BLOCK_SELECTOR}__icon--${props.iconSlot}`];
</script>

<style scoped lang="scss">
.base-button {
  &--naked {
    --background: transparent;
  }

  &--rounded {
    --border-radius: 2em;
  }

  &--clear {
    --padding-end: 0;
    --padding-start: 0;
  }

  &--medium {
    --color: var(--ion-color-dark);
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--end {
      right: 0;
    }

    &--start {
      left: 0;
    }
  }
}
</style>
