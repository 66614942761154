import { Capacitor } from '@capacitor/core';
import { Platform } from '@/enums/Platform';

export const setupGoogleAnalytics = () => {
  const gaId = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;

  if (!gaId) {
    return;
  }

  (function (d, s) {
    const t = d.getElementsByTagName(s)[0],
      e = d.createElement(s);
    e.async = true;
    e.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    t.parentNode.insertBefore(e, t);
  })(document, 'script');

  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push('js', new Date());

  window.dataLayer.push('config', gaId);
};

export const setupAxeptio = () => {
  const clientId = process.env.VUE_APP_AXEPTIO_CLIENT_ID;

  if (!clientId) {
    return;
  }

  window.axeptioSettings = {
    clientId,
    cookiesVersion: 'application torque tightening-fr',
  };

  (function (d, s) {
    const t = d.getElementsByTagName(s)[0],
      e = d.createElement(s);
    e.async = true;
    e.src = '//static.axept.io/sdk.js';
    t.parentNode.insertBefore(e, t);
  })(document, 'script');

  // Axeptio JS SDK doc : https://developers.axeptio.eu/cookies/axeptio-javascript-sdk
  // Axeptio SDK events doc : https://developers.axeptio.eu/cookies/axeptio-javascript-sdk/listen-to-sdk-events
  window._axcb = window._axcb || [];
  window._axcb.push((sdk) => {
    sdk.on('cookies:complete', (choices) => {
      if (choices.google_analytics) {
        setupGoogleAnalytics();
      }
    });
  });
};

export const init = () => {
  if (!window) {
    return;
  }

  const isMobile = Capacitor.getPlatform() === Platform.IOS || Capacitor.getPlatform() === Platform.ANDROID;

  if (isMobile) {
    setupGoogleAnalytics();
  } else {
    setupAxeptio();
  }
};
