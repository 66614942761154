<template>
  <div class="compute-wizard-step-container">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.compute-wizard-step-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  align-items: center;

  & > * {
    width: 100%;
  }
}
</style>
