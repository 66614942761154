<template>
  <IonApp>
    <IonRouterOutlet />
    <transition name="fade">
      <SplashScreen v-if="shouldDisplaySplashscreen" @dismiss-request="hideSplashscreen" />
    </transition>
  </IonApp>
</template>

<script setup lang="ts">
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useClampingStepStore } from '@/store/clampingStep';
import { useComputeWizardStore } from '@/store/computeWizard';
import { useFrictionStepStore } from '@/store/frictionStep';
import { useQualityStepStore } from '@/store/qualityStep';
import { useSizeStepStore } from '@/store/sizeStep';
import { useSurfaceStepStore } from '@/store/surfaceStep';
import SplashScreen from '@/components/SplashScreen.vue';
import useLangSettings from '@/composables/useLangSettings';

const route = useRoute();
const { initAppLanguage } = useLangSettings();

const preloadStore = async () => {
  useClampingStepStore();
  useComputeWizardStore();
  useFrictionStepStore();
  useQualityStepStore();
  useSizeStepStore();
  useSurfaceStepStore();
};

onMounted(() => {
  preloadStore();
  initAppLanguage();
});

const shouldDisplaySplashscreen = ref<boolean>(true);

const hideSplashscreen = () => {
  shouldDisplaySplashscreen.value = false;
};

watch(route, () => {
  const ionContents = [...document.querySelectorAll('ion-content')];

  for (let ionContent of ionContents) {
    ionContent.scrollToTop(500);
  }
});
</script>

<style lang="scss">
@use '@/assets/scss/lib/functions/px-to-rem' as *;

#axeptio_main_button {
  right: px-to-rem(18px) !important;
  left: unset !important;
}
</style>
