import { FrictionType } from '@/enums/Friction';

export interface FrictionTypeValue {
  type: FrictionType;
  coefficientMin: number;
  coefficientMax: number;
}

export const frictionTypeValues: Array<FrictionTypeValue> = [
  {
    type: FrictionType.Weak,
    coefficientMin: 0.06,
    coefficientMax: 0.1,
  },
  {
    type: FrictionType.Medium,
    coefficientMin: 0.1,
    coefficientMax: 0.2,
  },
  {
    type: FrictionType.Strong,
    coefficientMin: 0.2,
    coefficientMax: 0.4,
  },
];
