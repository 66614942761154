import { useI18n } from 'vue-i18n';
import { Device } from '@capacitor/device';
import { LocalStorageKey } from '@/enums/LocalStorage';
import { Lang } from '@/enums/Lang';

export default () => {
  const currentI18n = useI18n({ useScope: 'global' });

  const initAppLanguage = async () => {
    const lang = localStorage.getItem(LocalStorageKey.SettingLocale);

    if (lang == undefined) {
      const deviceLang = await Device.getLanguageCode();
      if (deviceLang.value == Lang.Fr.Iso) {
        updateLangIso(Lang.Fr.Iso);
      } else {
        updateLangIso(Lang.En.Iso);
      }
    }
  };

  const updateLangIso = (iso: string) => {
    currentI18n.locale.value = iso;
    localStorage.setItem(LocalStorageKey.SettingLocale, iso);
  };

  const currentLocale = (): string => {
    const lang = localStorage.getItem(LocalStorageKey.SettingLocale);
    if (!lang) {
      updateLangIso(Lang.En.Iso);
    }
    return lang || Lang.En.Iso;
  };

  return {
    initAppLanguage,
    updateLangIso,
    currentLocale,
  };
};
