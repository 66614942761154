import { GradeVariant, QualityMaterial } from '@/enums/Quality';

export interface QualityMaterialVariant {
  material: QualityMaterial;
  variant: GradeVariant;
  value: number;
}

export function defaultValue(material: QualityMaterial): GradeVariant {
  switch (material) {
    case QualityMaterial.StainlessSteel:
      return GradeVariant.Austenitic_80;
    case QualityMaterial.SaeGrade:
      return GradeVariant.Grade_5;
    default:
      return GradeVariant.Value_8_8;
  }
}

export const carbonSteelVariant: Array<QualityMaterialVariant> = [
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_4_6,
    value: 240,
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_4_8,
    value: 340,
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_5_6,
    value: 300,
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_5_8,
    value: 420,
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_6_8,
    value: 480,
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_8_8,
    value: -1, // Override in src/store/qualityStep.ts:qualityObjectMappedValue()
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_9_8,
    value: 720,
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_10_9,
    value: 940,
  },
  {
    material: QualityMaterial.CarbonSteel,
    variant: GradeVariant.Value_12_9,
    value: 1100,
  },
];

export const stainlessSteelVariant: Array<QualityMaterialVariant> = [
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Austenitic_50,
    value: 210,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Austenitic_70,
    value: 450,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Austenitic_80,
    value: 600,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Austenitic_100,
    value: 800,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Duplex_70,
    value: 450,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Duplex_80,
    value: 600,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Duplex_100,
    value: 800,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Martensitic_50,
    value: 250,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Martensitic_70,
    value: 410,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Martensitic_80,
    value: 640,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Martensitic_110,
    value: 820,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Ferritic_45,
    value: 250,
  },
  {
    material: QualityMaterial.StainlessSteel,
    variant: GradeVariant.Ferritic_60,
    value: 410,
  },
];

export const saeGradeVariant: Array<QualityMaterialVariant> = [
  {
    material: QualityMaterial.SaeGrade,
    variant: GradeVariant.Grade_2,
    value: -1, // Override in src/store/qualityStep.ts:qualityObjectMappedValue()
  },
  {
    material: QualityMaterial.SaeGrade,
    variant: GradeVariant.Grade_5,
    value: -1, // Override in src/store/qualityStep.ts:qualityObjectMappedValue()
  },
  {
    material: QualityMaterial.SaeGrade,
    variant: GradeVariant.Grade_7,
    value: 797.9,
  },
  {
    material: QualityMaterial.SaeGrade,
    variant: GradeVariant.Grade_8,
    value: 896.3,
  },
];
