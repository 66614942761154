<template>
  <div class="navigation-footer">
    <BaseButton
      v-if="!isFirst"
      rounded
      color="medium"
      class="navigation-footer__button"
      :icon="Icons.BackArrow"
      icon-slot="start"
      @click="$emit('requestPreviousStep')"
    >
      {{ $t('wizard.previous') }}
    </BaseButton>
    <BaseButton rounded class="navigation-footer__button" :icon="Icons.NextArrow" @click="$emit('requestNextStep')">
      {{ nextButtonLabel }}
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
import { Icons } from '@/enums/Icons';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import BaseButton from '@/components/BaseButton.vue';

const { t } = useI18n();

defineEmits(['requestNextStep', 'requestPreviousStep']);

const props = defineProps<{
  isFirst: boolean;
  isLast: boolean;
}>();

const nextButtonLabel = computed(() => {
  return props.isLast ? t('wizard.submit') : t('wizard.next');
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/functions/px-to-rem' as *;

.navigation-footer {
  @extend %page-content-spacing;

  margin-top: auto;
  display: flex;
  gap: 1.25em;
  left: 0.875em;
  right: 0.875em;
  width: 100%;

  &__button {
    margin-top: px-to-rem(20px);
    margin-bottom: px-to-rem(20px);
    flex: 1;
  }
}
</style>
