import { useI18n } from 'vue-i18n';
import { TranslateElement } from '@/types/TranslateElement';

export default () => {
  const { t } = useI18n();

  const translate = (toTranslate: any, enumName: string): Array<TranslateElement> => {
    return Object.keys(toTranslate).map((item) => ({
      label: t(`${enumName}.${item}`),
      value: toTranslate[item],
    }));
  };

  return { translate };
};
