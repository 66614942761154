import { modalController } from '@ionic/vue';
import { ComponentProps, ComponentRef } from '@ionic/core';

export default function () {
  const displayAsModal = async <T extends ComponentRef = ComponentRef>(
    component: T,
    componentProps?: ComponentProps<T>,
    onDismissListener?: (result: any) => void
  ) => {
    const modal = await modalController.create({
      component: component,
      componentProps: componentProps,
      cssClass: 'modal--base-bottom-sheet',
      initialBreakpoint: 1,
      breakpoints: [0, 1],
      canDismiss: false,
      handle: false,
    });

    await modal.present();

    return modal.onDidDismiss().then((data: any) => {
      if (data && onDismissListener) {
        onDismissListener(data);
      }
    });
  };

  const dismissCurrentModal = async () => {
    const modal = await modalController.getTop();
    modal.canDismiss = true;
    await modal.dismiss();
  };

  return { displayAsModal, dismissCurrentModal };
}
