<template>
  <ComputeWizardStepContainer class="compute-wizard-friction">
    <SegmentSelect
      class="compute-wizard-friction__type"
      v-model="frictionStepStore.type"
      :pop-up-labels="frictionTypePopUpLabels"
      :options="frictionTypeTranslations"
      @on-change="onFrictionTypeChange"
    ></SegmentSelect>

    <div class="compute-wizard-friction__recommendation-container">
      <IonText class="compute-wizard-friction__recommendation-title">{{ $t('friction.recommendation_title') }}</IonText>
      <IonText class="compute-wizard-friction__recommendation-content">{{
        $t('friction.recommendation_content')
      }}</IonText>
    </div>

    <img class="compute-wizard-friction__image" :src="Friction" />

    <ComputeWizardInputAccordion
      class="compute-wizard-friction__input-accordion"
      :title="$t('friction.specific_settings.accordion_label')"
    >
      <IonItem lines="full" class="compute-wizard-friction__input-accordion-item" slot="content">
        <IonLabel class="compute-wizard-friction__specific-coefficient-title">{{
          $t('friction.specific_coefficient_title')
        }}</IonLabel>
      </IonItem>

      <IonItem lines="full" class="compute-wizard-friction__input-accordion-item" slot="content">
        <IonLabel>{{ $t('friction.coefficient.min_input_label') }}</IonLabel>
        <IonInput
          class="compute-wizard-friction__coefficient-input"
          type="number"
          inputMode="decimal"
          pattern="[0-9]*"
          :step="frictionStepStore.specificCoefficientStep"
          :min="frictionStepStore.specificCoefficientMinMinimumValue + frictionStepStore.specificCoefficientStep"
          :max="frictionStepStore.specificCoefficientMinMaximumValue - frictionStepStore.specificCoefficientStep"
          placeholder="x"
          v-model="specificCoefficientMin"
        />
      </IonItem>
      <IonItem lines="full" class="compute-wizard-friction__input-accordion-item" slot="content">
        <IonLabel>{{ $t('friction.coefficient.max_input_label') }}</IonLabel>
        <IonInput
          class="compute-wizard-friction__coefficient-input"
          type="number"
          inputMode="decimal"
          pattern="[0-9]*"
          :step="frictionStepStore.specificCoefficientStep"
          :min="frictionStepStore.specificCoefficientMaxMinimumValue + frictionStepStore.specificCoefficientStep"
          :max="frictionStepStore.specificCoefficientMaxMaximumValue - frictionStepStore.specificCoefficientStep"
          placeholder="x"
          v-model="specificCoefficientMax"
        />
      </IonItem>

      <div
        class="compute-wizard-friction__input-accordion-item compute-wizard-friction__input-accordion-item--calculator"
        slot="content"
      >
        <IonLabel class="compute-wizard-friction__friction-coefficient-title">{{
          $t('friction.friction_coefficient_title')
        }}</IonLabel>

        <div class="compute-wizard-friction__friction-calculator-title-wrapper">
          <IonLabel class="compute-wizard-friction__friction-calculator-subtitle">{{
            $t('friction.calculator.min')
          }}</IonLabel>
        </div>
        <IonGrid class="compute-wizard-friction__calculator-grid">
          <IonRow class="compute-wizard-friction__calculator-row">
            <IonCol class="compute-wizard-friction__calculator-col">
              <IonItem class="compute-wizard-friction__calculator-item">
                <IonLabel
                  for="min-measured-torque"
                  class="compute-wizard-friction__calculator-label"
                  position="stacked"
                  >{{ $t('friction.calculator.measured_torque') }}</IonLabel
                >
                <IonInput
                  id="min-measured-torque"
                  class="compute-wizard-friction__calculator-input"
                  type="number"
                  inputMode="decimal"
                  pattern="[0-9]*"
                  :step="minMeasuredTorqueInputStep"
                  min="0.01"
                  v-model="minMeasuredTorque"
                  placeholder="x"
                />
              </IonItem>
            </IonCol>
            <IonCol class="compute-wizard-friction__calculator-col">
              <IonItem class="compute-wizard-friction__calculator-item">
                <IonLabel
                  for="min-measured-preload"
                  class="compute-wizard-friction__calculator-label"
                  position="stacked"
                  >{{ $t('friction.calculator.measured_preload') }}</IonLabel
                >
                <IonInput
                  id="min-measured-preload"
                  class="compute-wizard-friction__calculator-input"
                  type="number"
                  inputMode="decimal"
                  pattern="[0-9]*"
                  step="1"
                  min="1"
                  v-model="minMeasuredPreload"
                  placeholder="x"
                />
              </IonItem>
            </IonCol>
            <IonCol class="compute-wizard-friction__calculator-col compute-wizard-friction__calculator-col--button">
              <IonButton
                class="compute-wizard-friction__calculator-button"
                color="light"
                @click="onMinMeasuredValueSelected"
              >
                <div class="compute-wizard-friction__calculator-button-inner">
                  <span class="compute-wizard-friction__calculator-button-label">{{
                    $t('friction.coefficient.min_input_label')
                  }}</span>
                  <span class="compute-wizard-friction__calculator-button-value">{{ minMeasured }}</span>
                </div>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div class="compute-wizard-friction__friction-calculator-title-wrapper">
          <IonLabel class="compute-wizard-friction__friction-calculator-subtitle">{{
            $t('friction.calculator.max')
          }}</IonLabel>
        </div>
        <IonGrid class="compute-wizard-friction__calculator-grid">
          <IonRow class="compute-wizard-friction__calculator-row">
            <IonCol class="compute-wizard-friction__calculator-col">
              <IonItem class="compute-wizard-friction__calculator-item">
                <IonLabel
                  for="max-measured-torque"
                  class="compute-wizard-friction__calculator-label"
                  position="stacked"
                  >{{ $t('friction.calculator.measured_torque') }}</IonLabel
                >
                <IonInput
                  id="max-measured-torque"
                  class="compute-wizard-friction__calculator-input"
                  type="number"
                  inputMode="decimal"
                  pattern="[0-9]*"
                  :step="maxMeasuredTorqueInputStep"
                  min="0.01"
                  v-model="maxMeasuredTorque"
                  placeholder="x"
                />
              </IonItem>
            </IonCol>
            <IonCol class="compute-wizard-friction__calculator-col">
              <IonItem class="compute-wizard-friction__calculator-item">
                <IonLabel
                  for="max-measured-preload"
                  class="compute-wizard-friction__calculator-label"
                  position="stacked"
                  >{{ $t('friction.calculator.measured_preload') }}</IonLabel
                >
                <IonInput
                  id="max-measured-preload"
                  class="compute-wizard-friction__calculator-input"
                  type="number"
                  inputMode="decimal"
                  pattern="[0-9]*"
                  step="1"
                  min="1"
                  v-model="maxMeasuredPreload"
                  placeholder="x"
                />
              </IonItem>
            </IonCol>
            <IonCol class="compute-wizard-friction__calculator-col compute-wizard-friction__calculator-col--button">
              <IonButton
                class="compute-wizard-friction__calculator-button"
                color="light"
                @click="onMaxMeasuredValueSelected"
              >
                <div class="compute-wizard-friction__calculator-button-inner">
                  <span class="compute-wizard-friction__calculator-button-label">{{
                    $t('friction.coefficient.max_input_label')
                  }}</span>
                  <span class="compute-wizard-friction__calculator-button-value">{{ maxMeasured }}</span>
                </div>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </ComputeWizardInputAccordion>
  </ComputeWizardStepContainer>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { IonButton, IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRow, IonText } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { InvalidFormatType } from '@/enums/InvalidFormat';
import { FrictionType } from '@/enums/Friction';
import { ConditionType } from '@/enums/Condition';
import { TranslateElement } from '@/types/TranslateElement';
import { useFrictionStepStore } from '@/store/frictionStep';
import useTranslateEnums from '@/composables/useTranslateEnums';
import { getSpecificFrictionCoefficient } from '@/composables/useCalculationResult';
import useAlertOnEvent from '@/composables/useAlertOnEvent';
import SegmentSelect from '@/components/SegmentSelect.vue';
import ComputeWizardStepContainer from '@/components/ComputeWizard/ComputeWizardStepContainer.vue';
import ComputeWizardInputAccordion from '@/components/ComputeWizard/ComputeWizardInputAccordion.vue';
import Friction from '@/assets/images/step-schemas/friction.png';

const { t } = useI18n();
const { translate } = useTranslateEnums();
const alertManager = useAlertOnEvent();
const frictionStepStore = useFrictionStepStore();

const frictionTypeTranslations = computed((): Array<TranslateElement> => {
  return translate(FrictionType, 'friction.types');
});

const frictionTypePopUpLabels = computed(() => {
  return [t('friction.popup_labels.weak'), t('friction.popup_labels.medium'), t('friction.popup_labels.strong')];
});

const minMeasuredTorque = ref(undefined);
const minMeasuredPreload = ref(undefined);

const minMeasuredTorqueInputStep = computed(() => {
  return minMeasuredTorque.value && minMeasuredTorque.value > 100 ? 0.1 : 0.01;
});

const maxMeasuredTorque = ref(undefined);
const maxMeasuredPreload = ref(undefined);

const maxMeasuredTorqueInputStep = computed(() => {
  return maxMeasuredTorque.value && maxMeasuredTorque.value > 100 ? 0.1 : 0.01;
});

const specificCoefficientMax = ref(frictionStepStore.specificCoefficientMax);
const specificCoefficientMin = ref(frictionStepStore.specificCoefficientMin);

const minMeasured = computed(() => {
  if (minMeasuredTorque.value && minMeasuredPreload.value) {
    return getSpecificFrictionCoefficient(parseFloat(minMeasuredTorque.value), parseFloat(minMeasuredPreload.value));
  }

  return undefined;
});

const maxMeasured = computed(() => {
  if (maxMeasuredTorque.value && maxMeasuredPreload.value) {
    return getSpecificFrictionCoefficient(parseFloat(maxMeasuredTorque.value), parseFloat(maxMeasuredPreload.value));
  }

  return undefined;
});

const onFrictionTypeChange = (newValue: FrictionType | undefined) => {
  frictionStepStore.setType(newValue);

  specificCoefficientMin.value = frictionStepStore.specificCoefficientMin;
  specificCoefficientMax.value = frictionStepStore.specificCoefficientMax;
};

const validateValue = async (field_name: string, current_value: number, decimalCount = 0): Promise<boolean> => {
  if (current_value && current_value <= 0) {
    await alertManager.fireConditionAlert({
      field_name,
      current_value,
      conditionType: ConditionType.GreaterThan,
      target: 0,
    });
    return false;
  }

  const valueDecimalCount = current_value.toString().split('.')[1];

  if (valueDecimalCount && valueDecimalCount.length > decimalCount) {
    await alertManager.fireInvalidFormat({
      field_name,
      current_value,
      invalidFormatType: decimalCount === 0 ? InvalidFormatType.Integer : InvalidFormatType.MaxDecimal,
      maxDecimal: decimalCount,
      integer: decimalCount === 0,
    });
    return false;
  }

  return true;
};

const torqueAndPreloadAreValid = async (
  fieldNamePrefix: string,
  torque: number | undefined,
  preload: number | undefined
): Promise<boolean> => {
  let torqueIsValid = true;
  let preloadIsValid = true;

  if (torque !== undefined) {
    torqueIsValid = await validateValue(
      `${fieldNamePrefix} ${t('friction.calculator.measured_torque')}`,
      torque,
      torque > 100 ? 1 : 2
    );
  }

  if (torqueIsValid && preload !== undefined) {
    preloadIsValid = await validateValue(`${fieldNamePrefix} ${t('friction.calculator.measured_preload')}`, preload);
  }

  return torqueIsValid && preloadIsValid;
};

const onMinMeasuredValueSelected = async (): Promise<void> => {
  if (
    !(await torqueAndPreloadAreValid(t('friction.calculator.min'), minMeasuredTorque.value, minMeasuredPreload.value))
  ) {
    return;
  }

  if (minMeasured.value && !Number.isNaN(minMeasured.value) && Number.isFinite(minMeasured.value)) {
    frictionStepStore.setSpecificCoefficientMin(minMeasured.value);
    specificCoefficientMin.value = frictionStepStore.specificCoefficientMin;
  }
};

const onMaxMeasuredValueSelected = async (): Promise<void> => {
  if (
    !(await torqueAndPreloadAreValid(t('friction.calculator.max'), maxMeasuredTorque.value, maxMeasuredPreload.value))
  ) {
    return;
  }

  if (maxMeasured.value && !Number.isNaN(maxMeasured.value) && Number.isFinite(maxMeasured.value)) {
    frictionStepStore.setSpecificCoefficientMax(maxMeasured.value);
    specificCoefficientMax.value = frictionStepStore.specificCoefficientMax;
  }
};

watch(specificCoefficientMin, (currentValue) => {
  frictionStepStore.setSpecificCoefficientMin(currentValue);

  specificCoefficientMin.value = frictionStepStore.specificCoefficientMin;
  specificCoefficientMax.value = frictionStepStore.specificCoefficientMax;
});

watch(specificCoefficientMax, (currentValue) => {
  frictionStepStore.setSpecificCoefficientMax(currentValue);

  specificCoefficientMin.value = frictionStepStore.specificCoefficientMin;
  specificCoefficientMax.value = frictionStepStore.specificCoefficientMax;
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/lib/placeholders/page' as *;
@use '@/assets/scss/lib/placeholders/bold' as *;
@use '@/assets/scss/lib/functions/px-to-em' as *;

.compute-wizard-friction {
  display: flex;
  flex-direction: column;

  &__recommendation-container {
    @extend %page-content-spacing;

    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__recommendation-title {
    @extend %bold;

    margin-top: px-to-em(20px);
  }

  &__recommendation-content {
    margin-top: px-to-em(10px);
    margin-bottom: px-to-em(20px);
  }

  &__friction-coefficient-title {
    font-size: px-to-em(16px);
    margin-top: px-to-em(10px);
    margin-bottom: px-to-em(20px);
  }

  &__coefficient-input {
    text-align: right;
  }

  &__input-accordion-item {
    --padding-top: #{px-to-em(8px)};
    --padding-bottom: #{px-to-em(8px)};

    &--calculator {
      padding: px-to-em(10px) px-to-em(20px);
      display: flex;
      flex-direction: column;
    }
  }

  &__specific-coefficient-title,
  &__friction-coefficient-title {
    margin-top: px-to-em(18px, 16px);
    margin-bottom: 0;
  }

  &__friction-calculator-title-wrapper {
    margin-top: px-to-em(20px);
    margin-bottom: px-to-em(5px);
  }

  &__friction-calculator-subtitle {
    font-size: px-to-em(16px);
  }

  &__calculator-grid {
    width: 100%;
    padding: 0;
  }

  &__calculator-row {
    gap: px-to-em(10px);
  }

  &__calculator-col {
    padding: 0;

    &--button {
      flex: 0;
    }
  }

  &__calculator-item {
    --padding-start: 0;
    --inner-padding-top: #{px-to-em(10px)};
    --inner-padding-bottom: #{px-to-em(10px)};
    --inner-padding-start: #{px-to-em(10px)};
    --inner-padding-end: #{px-to-em(10px)};
    --inner-border-width: 0;
    border-radius: px-to-em(10px);
    text-align: center;
    border: 1px solid;
    border-color: var(--cetim-color-grey-mischka);
  }

  &__calculator-label {
    margin-left: 0;
    margin-right: 0;
  }

  &__calculator-button {
    height: 100%;
    width: 100%;
    margin: 0;
  }

  &__calculator-input {
    --padding-end: 0;
  }

  &__calculator-button-inner {
    display: flex;
    flex-direction: column;
  }

  &__calculator-button-label,
  &__calculator-button-value {
    margin: px-to-em(5px) 0;
  }

  &__image {
    margin: px-to-em(20px) auto;
    width: 70%;
    object-fit: contain;
  }
}
</style>
