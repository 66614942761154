import { useLocalStorage } from '@vueuse/core';
import { LocalStorageKey } from '@/enums/LocalStorage';

export default () => {
  // The value must be updated if there is new articles or infos
  const lastUpdate = useLocalStorage(LocalStorageKey.SettingsNewsLastUpdate, Date.parse('2022-09-22T00:00:00.000Z'));

  const lastVisit = useLocalStorage(LocalStorageKey.SettingsNewsLastVisit, new Date().getTime());

  const isVisited = useLocalStorage(LocalStorageKey.SettingsNewsIsVisited, false);

  const hasNews = (): boolean => {
    return !isVisited.value || lastVisit.value <= lastUpdate.value;
  };

  const updateLastVisit = (): void => {
    lastVisit.value = new Date().getTime();
  };

  const checkLastVisit = (): void => {
    if (hasNews()) {
      isVisited.value = true;
      updateLastVisit();
    }
  };

  updateLastVisit();

  return {
    lastUpdate,
    lastVisit,
    isVisited,
    hasNews,
    updateLastVisit,
    checkLastVisit,
  };
};
