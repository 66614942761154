<template>
  <ComputeWizardStepContainer class="compute-wizard-clamping">
    <DropdownSelect
      class="compute-wizard-clamping__tool-list"
      :label="$t('clamping.tool_list_input_label')"
      v-model="clampingStepStore.tool"
      :options="toolList"
      @ionChange="onToolChange"
    />

    <img class="compute-wizard-clamping__image" :src="Clamping" />

    <ComputeWizardInputAccordion
      class="compute-wizard-clamping__input-accordion"
      :title="$t('clamping.specific_settings.accordion_label')"
      :should-be-open="specificValueShouldBeDisplayed"
    >
      <IonItem lines="full" class="compute-wizard-clamping__input-accordion-item" slot="content">
        <IonLabel>{{ $t('clamping.specific_settings.input_label') }}</IonLabel>
        <IonInput
          class="compute-wizard-clamping__specific-input"
          type="number"
          inputMode="decimal"
          pattern="[0-9]*"
          step="0.1"
          min="0.1"
          max="100"
          v-model="clampingStepStore.specificSetting"
          @ionChange="onSpecificSettingChange"
          placeholder="x"
          @keyup.enter="$emit('requestNextStep')"
        />
      </IonItem>
    </ComputeWizardInputAccordion>
  </ComputeWizardStepContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { IonItem, IonLabel, IonInput } from '@ionic/vue';
import { ClampingTool } from '@/enums/Clamping';
import { useClampingStepStore } from '@/store/clampingStep';
import useTranslateEnums from '@/composables/useTranslateEnums';
import DropdownSelect from '@/components/DropdownSelect.vue';
import ComputeWizardStepContainer from '@/components/ComputeWizard/ComputeWizardStepContainer.vue';
import ComputeWizardInputAccordion from '@/components/ComputeWizard/ComputeWizardInputAccordion.vue';
import Clamping from '@/assets/images/step-schemas/clamping-tool.png';

defineEmits(['requestNextStep']);

const clampingStepStore = useClampingStepStore();
const { translate } = useTranslateEnums();

const specificValueShouldBeDisplayed = computed(() => {
  return clampingStepStore.tool === undefined || clampingStepStore.tool?.toString() === '';
});

const toolList = computed(() => {
  return translate(ClampingTool, 'clamping.tools');
});

const onToolChange = () => {
  clampingStepStore.onToolUpdate();
};

const onSpecificSettingChange = () => {
  clampingStepStore.onSpecificUpdate();
};
</script>

<style lang="scss" scoped>
@use '@/assets/scss/lib/functions/px-to-em' as *;

.compute-wizard-clamping {
  &__tool-list {
    width: 100%;
  }

  &__tool-list {
    --padding-top: #{px-to-em(19px)};
    --padding-bottom: #{px-to-em(19px)};
  }

  &__input-accordion-item {
    --padding-top: #{px-to-em(8px)};
    --padding-bottom: #{px-to-em(8px)};
  }

  &__specific-input {
    text-align: right;
  }

  &__image {
    margin: px-to-em(20px) auto;
    width: 70%;
    object-fit: contain;
  }
}
</style>
