import { defineStore } from 'pinia';
import { SurfaceGenericTypeValue, surfaceGenericTypeValues } from '@/data/SurfaceValue';
import { SurfaceGenericType } from '@/enums/Surface';
import useFindEnumKeyFromValue from '@/composables/useFindEnumKeyFromValue';
import { useI18n } from 'vue-i18n';
import { useSizeStepStore } from '@/store/sizeStep';
import Logger from '@/utils/Logger';
import CalculationConstant from '@/data/CalculationConstant';

export interface SurfaceStepStore {
  genericType: SurfaceGenericType | undefined;
  specificInnerBearingDiameter: number | undefined;
  specificExternalBearingDiameter: number | undefined;
}

export const useSurfaceStepStore = defineStore('surfaceStep', {
  state: (): SurfaceStepStore => {
    return {
      genericType: SurfaceGenericType.Hexagonal,
      specificInnerBearingDiameter: undefined,
      specificExternalBearingDiameter: undefined,
    };
  },
  getters: {
    genericTypeValue(state): SurfaceGenericTypeValue | undefined {
      if (state.genericType) {
        return surfaceGenericTypeValues.find((item: SurfaceGenericTypeValue): boolean => {
          return item.type === state.genericType;
        });
      }

      return undefined;
    },
    specificInnerBearingDiameterStep(state): number {
      return !state.specificInnerBearingDiameter || state.specificInnerBearingDiameter < 100 ? 0.01 : 0.1;
    },
    specificInnerBearingDiameterMinValue(): number {
      const sizeStepStore = useSizeStepStore();

      return sizeStepStore.sizeValue;
    },
    specificInnerBearingDiameterMaxValue(): number {
      const sizeStepStore = useSizeStepStore();

      return sizeStepStore.sizeValue * 10;
    },
    specificExternalBearingDiameterStep(state): number {
      return !state.specificExternalBearingDiameter || state.specificExternalBearingDiameter < 100 ? 0.01 : 0.1;
    },
    specificExternalBearingDiameterMinValue(state): number {
      return state.specificInnerBearingDiameter
        ? state.specificInnerBearingDiameter + this.specificExternalBearingDiameterStep
        : this.specificInnerBearingDiameterMinValue;
    },
    specificExternalBearingDiameterMaxValue(): number {
      return this.specificInnerBearingDiameterMaxValue;
    },
    innerBearingDiameter(state): number {
      if (this.genericTypeValue) {
        return this.genericTypeValue.innerDiameter;
      }
      return state.specificInnerBearingDiameter || 0;
    },
    externalBearingDiameter(state): number {
      if (this.genericTypeValue) {
        return this.genericTypeValue.externalDiameter;
      }
      return state.specificExternalBearingDiameter || 0;
    },
    surfaceInnerDiameter(state): number {
      if (state.specificInnerBearingDiameter) {
        Logger.log('Diamètre intérieur spécifique saisi :');
        Logger.log('dh = d_i = ', state.specificInnerBearingDiameter);
        return state.specificInnerBearingDiameter;
      } else {
        Logger.log('Pas de diamètre intérieur spécifique saisi :');
        Logger.log('dh = d_i = 1.085894 * Size_value + 0.153965');
        Logger.log(
          '    = ',
          CalculationConstant.CalculDi1,
          ' * ',
          useSizeStepStore().sizeValue,
          ' + ',
          CalculationConstant.CalculDi2
        );
        const result = CalculationConstant.CalculDi1 * useSizeStepStore().sizeValue + CalculationConstant.CalculDi2;
        Logger.log('    = ', result);

        return result;
      }
    },
    surfaceExternalDiameter(state): number {
      if (state.specificExternalBearingDiameter) {
        Logger.log('Diamètre exterieur spécifique saisi :');
        Logger.log('do = d_w = ', state.specificExternalBearingDiameter);
        return state.specificExternalBearingDiameter;
      } else {
        Logger.log('Pas de diamètre exterieur spécifique saisi :');
        Logger.log("Diam ext. d'appui = ", state.genericType);
        Logger.log('cst_BS = ', this.innerBearingDiameter);
        Logger.log('Coef_BS = ', this.externalBearingDiameter);

        Logger.log('do = d_w = coef_BS*Size_value + cst_BS');
        Logger.log(
          '    = ',
          this.externalBearingDiameter,
          ' * ',
          useSizeStepStore().sizeValue,
          ' + ',
          this.innerBearingDiameter
        );
        const result = this.externalBearingDiameter * useSizeStepStore().sizeValue + this.innerBearingDiameter;
        Logger.log('    = ', result);
        return result;
      }
    },
    roundedInnerBearingDiameter(): number {
      const numberOfDecimal = useSizeStepStore().sizeValue > 20 ? 0 : 1;
      return this.innerBearingDiameter ? Number(Number(this.surfaceInnerDiameter).toFixed(numberOfDecimal)) : 0;
    },
    roundedExternalBearingDiameter(): number {
      const numberOfDecimal = useSizeStepStore().sizeValue > 20 ? 0 : 1;
      return this.externalBearingDiameter ? Number(Number(this.surfaceExternalDiameter).toFixed(numberOfDecimal)) : 0;
    },
    stepButtonValue(): string {
      const { t } = useI18n();

      if (this.genericType) {
        return t(`surface.diameter_values.${useFindEnumKeyFromValue(SurfaceGenericType, this.genericType)}`, {
          inner: this.roundedInnerBearingDiameter,
          external: this.roundedExternalBearingDiameter,
        });
      }

      return t('surface.specific_values', {
        inner: this.roundedInnerBearingDiameter,
        external: this.roundedExternalBearingDiameter,
      });
    },
  },
  actions: {
    resetValues(): void {
      this.genericType = SurfaceGenericType.Hexagonal;
      this.specificInnerBearingDiameter = undefined;
      this.specificExternalBearingDiameter = undefined;
    },
    onGenericTypeUpdate(): void {
      if (this.genericType?.toString() !== '') {
        this.specificInnerBearingDiameter = undefined;
        this.specificExternalBearingDiameter = undefined;
      }
    },
    onSpecificInnerBearingDiameterUpdate(): void {
      if (this.specificInnerBearingDiameter?.toString() === '') {
        if (
          (this.specificExternalBearingDiameter === undefined ||
            this.specificExternalBearingDiameter?.toString() === '') &&
          (this.genericType === undefined || this.genericType?.toString() === '')
        ) {
          this.genericType = SurfaceGenericType.Hexagonal;
        }
      } else {
        this.genericType = undefined;
      }
    },
    onSpecificExternalBearingDiameterUpdate(): void {
      if (this.specificExternalBearingDiameter?.toString() === '') {
        if (
          (this.specificInnerBearingDiameter === undefined || this.specificInnerBearingDiameter?.toString() === '') &&
          (this.genericType === undefined || this.genericType?.toString() === '')
        ) {
          this.genericType = SurfaceGenericType.Hexagonal;
        }
      } else {
        this.genericType = undefined;
      }
    },
  },
});
