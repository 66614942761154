import { useQualityStepStore } from '@/store/qualityStep';
import { ConditionType } from '@/enums/Condition';
import useAlertOnEvent from '@/composables/useAlertOnEvent';
import { useI18n } from 'vue-i18n';
import { InvalidFormatType } from '@/enums/InvalidFormat';

export default () => {
  const alertManager = useAlertOnEvent();
  const qualityStepStore = useQualityStepStore();
  const { t } = useI18n();

  const validateSpecificValues = async (): Promise<boolean> => {
    if (qualityStepStore.specificValue !== undefined && qualityStepStore.specificValue < 1) {
      await alertManager.fireConditionAlert({
        field_name: t('quality.specific_settings.input_label_simplified'),
        current_value: qualityStepStore.specificValue,
        conditionType: ConditionType.GreaterThan,
        target: 0,
      });
      return false;
    }

    return true;
  };

  const validateDecimalValues = async (): Promise<boolean> => {
    if (qualityStepStore.specificValue !== undefined && !Number.isInteger(qualityStepStore.specificValue)) {
      await alertManager.fireInvalidFormat({
        field_name: t('quality.specific_settings.input_label_simplified'),
        current_value: qualityStepStore.specificValue,
        invalidFormatType: InvalidFormatType.Integer,
        integer: true,
      });
      return false;
    }
    return true;
  };

  const validateTheStep = async (): Promise<boolean> => {
    return (await validateSpecificValues()) && (await validateDecimalValues());
  };

  return {
    validateTheStep,
    validateSpecificValues,
    validateDecimalValues,
  };
};
