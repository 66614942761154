import { defineStore } from 'pinia';
import { SizeUnit, SizeTypeImperial, SizeTypeMetric } from '@/enums/Size';
import {
  defaultSizePitchValue,
  imperialValues,
  metricValues,
  SizeImperialValue,
  SizeMetricValue,
} from '@/data/SizeValues';
import { useI18n } from 'vue-i18n';
import useTranslateEnums from '@/composables/useTranslateEnums';
import CalculationConstant from '@/data/CalculationConstant';

export interface SizeStepStore {
  unit: SizeUnit;
  type: SizeTypeImperial | SizeTypeMetric | string;
  size: number;
}

export const useSizeStepStore = defineStore('sizeStep', {
  state: (): SizeStepStore => {
    return {
      unit: SizeUnit.Metric,
      type: SizeTypeMetric.Big,
      size: defaultSizePitchValue,
    };
  },
  getters: {
    unitValues(state): Array<SizeMetricValue | SizeImperialValue> {
      return state.unit === SizeUnit.Metric ? metricValues : imperialValues;
    },
    selectedObject(state): SizeMetricValue | SizeImperialValue {
      return (
        this.unitValues.find((item: SizeMetricValue | SizeImperialValue) => item.value === state.size) ||
        metricValues[0]
      );
    },
    sizeValue(): number {
      return this.selectedObject?.value || -1;
    },
    pitchValue(): number {
      let value = -1;

      if (!this.selectedObject) {
        return value;
      }

      const selectedPitch: any = this.selectedObject.pitch;

      if (selectedPitch[this.type]) {
        value = selectedPitch[this.type];
      }

      return value;
    },
    stepButtonValue(): string {
      const { t } = useI18n();
      const { translate } = useTranslateEnums();

      const metricUnits = translate(SizeTypeMetric, 'size.weight');
      const imperialUnits = translate(SizeTypeImperial, 'size.weight');

      const sizeType = this.unit === SizeUnit.Metric ? metricUnits : imperialUnits;

      // @ts-ignore
      let value = this.selectedObject?.pitch[this.type];

      if (this.unit === SizeUnit.Imperial) {
        value = CalculationConstant.CoefficientInch / value;
      }

      const valueWording = `${this.unit === SizeUnit.Imperial ? 'TPI ' : ''}${value}`;

      return t('home.steps.size.value', {
        pitch_key: this.selectedObject?.key,
        type: sizeType.find((element) => {
          return element.value === this.type;
        })?.label,
        value: valueWording,
      });
    },
  },
  actions: {
    resetValues(): void {
      this.unit = SizeUnit.Metric;
      this.type = SizeTypeMetric.Big;
      this.size = defaultSizePitchValue;
    },
    setUnit(newUnit: SizeUnit): void {
      this.unit = newUnit;
    },
    setType(newType: SizeTypeImperial | SizeTypeMetric): void {
      this.type = newType;
    },
    setSize(newSize: number): void {
      this.size = newSize;
    },
  },
});
