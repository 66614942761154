<template>
  <div class="splashscreen">
    <div class="splashscreen__wrapper">
      <IonImg
        :class="['splashscreen__logo', { 'splashscreen__logo--visible': shouldDisplayCarnotLogo }]"
        :src="CarnotLogo"
      />
      <IonImg
        :class="[
          'splashscreen__logo',
          'splashscreen__logo--cetim',
          { 'splashscreen__logo--visible': shouldDisplayCetimLogo },
        ]"
        :src="CetimLogo"
      />
      <IonImg
        :class="['splashscreen__logo', { 'splashscreen__logo--visible': shouldDisplayQuatriumLogo }]"
        :src="QuatriumLogo"
      />
    </div>
    <IonText class="splashscreen__signature">{{ $t('splashscreen.signature') }}</IonText>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, defineEmits } from 'vue';
import { IonImg, IonText } from '@ionic/vue';
import CarnotLogo from '@/assets/images/logo-carnot.png';
import CetimLogo from '@/assets/images/logo-cetim.png';
import QuatriumLogo from '@/assets/images/logo-quatrium.png';

const shouldDisplayCarnotLogo = ref<boolean>(false);
const shouldDisplayCetimLogo = ref<boolean>(false);
const shouldDisplayQuatriumLogo = ref<boolean>(false);

const initialDelay = 250;
const timeInterval = 850;

const emits = defineEmits(['dismiss-request']);

onMounted(() => {
  setTimeout(() => {
    shouldDisplayCarnotLogo.value = true;
  }, initialDelay);
  setTimeout(() => {
    shouldDisplayCetimLogo.value = true;
  }, initialDelay + timeInterval);
  setTimeout(() => {
    shouldDisplayQuatriumLogo.value = true;
  }, initialDelay + timeInterval * 2);
  setTimeout(() => {
    emits('dismiss-request');
  }, initialDelay + timeInterval * 3);
});
</script>

<style lang="scss" scoped>
.splashscreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background: url('../assets/images/splash.png');

  &__wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__logo {
    max-width: 17vh;
    margin-top: 2em;
    margin-bottom: 2em;
    opacity: 0;
    transition: opacity 0.75s;

    &--cetim {
      max-width: 20rem;
    }

    &--visible {
      opacity: 1;
    }
  }

  &__signature {
    color: black;
    text-align: center;
    margin-bottom: 0.8em;
  }
}
</style>
