<template>
  <ComputPage
    class="compute-surface-page"
    :prev-url="RoutePath.COMPUTE_STEP_SIZE"
    :next-url="RoutePath.COMPUTE_STEP_FRICTION"
    :title="title"
    :subtitle="$t('home.steps.surface_subtitle.label')"
    :current-step="2"
  >
    <ComputeWizardSurface />
  </ComputPage>
</template>

<script lang="ts" setup>
import ComputPage from '@/components/ComputePage.vue';
import ComputeWizardSurface from '@/components/ComputeWizard/ComputeWizardSurface.vue';
import { RoutePath } from '@/enums/Router';
import { useSizeStepStore } from '@/store/sizeStep';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const sizeStepStore = useSizeStepStore();

const title = computed(() => {
  return sizeStepStore.selectedObject
    ? t('home.steps.surface.label_with_size_value', { size_value: sizeStepStore.selectedObject.key })
    : t('home.steps.surface.label');
});
</script>

<style lang="scss" scoped>
.compute-surface-page {
  //
}
</style>
