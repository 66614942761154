import { alertController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';
import { ConditionType } from '@/enums/Condition';
import { InvalidFormatType } from '@/enums/InvalidFormat';

export interface ConditionAlertOption {
  conditionType: ConditionType;
  field_name: string;
  current_value: string | number;
  target?: string | number;
  min?: string | number;
  max?: string | number;
}

export interface InvalidFormatAlertOption {
  invalidFormatType: InvalidFormatType;
  field_name: string;
  current_value: string | number;
  integer?: boolean;
  minDecimal?: number;
  maxDecimal?: number;
}

export default () => {
  const { t } = useI18n();

  const fireSimpleAlert = async (
    header: string,
    subHeader: string | undefined = undefined,
    message: string | undefined = undefined,
    buttons: Array<string> = ['close']
  ): Promise<void> => {
    const alert = await alertController.create({
      header,
      subHeader,
      message,
      buttons: buttons.map((item) => {
        return t(item);
      }),
    });

    await alert.present();
  };

  const fireInvalidFormat = async (option: InvalidFormatAlertOption): Promise<void> => {
    let header = '';

    if (option.integer) {
      header = t(`invalid_format.${option.invalidFormatType}`, {
        field_name: option.field_name,
      });
    }

    if (option.minDecimal !== undefined && option.maxDecimal !== undefined) {
      header = t(
        `invalid_format.${option.invalidFormatType}`,
        {
          field_name: option.field_name,
          count_min: option.minDecimal,
          count: option.maxDecimal,
        },
        option.maxDecimal
      );
    } else if (option.minDecimal !== undefined) {
      header = t(
        `invalid_format.${option.invalidFormatType}`,
        {
          field_name: option.field_name,
          count: option.minDecimal,
        },
        option.minDecimal
      );
    } else if (option.maxDecimal !== undefined) {
      header = t(
        `invalid_format.${option.invalidFormatType}`,
        {
          field_name: option.field_name,
          count: option.maxDecimal,
        },
        option.maxDecimal
      );
    }

    const message = t('alert.current_value', { current_value: option.current_value });

    await fireSimpleAlert(header, undefined, message);
  };

  const fireConditionAlert = async (option: ConditionAlertOption): Promise<void> => {
    let header = '';

    if (option.target !== undefined) {
      header = t(`condition.${option.conditionType}`, {
        field_name: option.field_name,
        target: option.target,
      });
    }

    if (option.min !== undefined && option.max !== undefined) {
      header = t(`condition.${option.conditionType}`, {
        field_name: option.field_name,
        min: option.min,
        max: option.max,
      });
    }

    const message = t('alert.current_value', { current_value: option.current_value });

    await fireSimpleAlert(header, undefined, message);
  };

  return {
    fireInvalidFormat,
    fireSimpleAlert,
    fireConditionAlert,
  };
};
