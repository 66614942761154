import { chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';

export const Icons = Object.freeze({
  BackArrow: chevronBackOutline,
  Friction: require('@/assets/icons/friction.svg'),
  Gear: require('@/assets/icons/gear.svg'),
  Light: require('@/assets/icons/light.svg'),
  NextArrow: chevronForwardOutline,
  Quality: require('@/assets/icons/quality.svg'),
  Size: require('@/assets/icons/size.svg'),
  Surface: require('@/assets/icons/surface.svg'),
  Tool: require('@/assets/icons/tool.svg'),
  Cross: require('@/assets/icons/cross.svg'),
  Info: require('@/assets/icons/info.svg'),
  Phone: require('@/assets/icons/phone.svg'),
  Save: require('@/assets/icons/save.svg'),
  Envelope: require('@/assets/icons/envelope.svg'),
});
